import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Carousel from "react-material-ui-carousel";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import "@/components/Elements/CSS/style.css"
import card3 from "@/assets/card3.svg";
import { url } from "inspector";
import topContainerData from "@/assets/topContainer.png"
import "../../../components/Elements/CSS/style.css";
import spinner from "@/assets/spinner.svg";
import Countdown from 'react-countdown';
import mervVideo from "../../../assets/Promohomepage.webm";
import { useHomeScreenBannerList } from "../api/getBannerData";

const Item = styled(Paper)(({ theme }) => ({
  background: "transparent",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
}));
interface topContainerProps {
  status: boolean;
  dataRecent: any;
}

export const TopContainer = ({ status, dataRecent }: topContainerProps) => {

  var result = new Date();
  var targetDate = result.setDate(result.getDate() + 2);
  const countDownDate = new Date(targetDate).getTime();
  const hidden = useMediaQuery("(min-width: 567px)");
  const bannerData = dataRecent?.homeSectionBanner?.[0];
  console.log(bannerData, "bannerData");
  const [counter, setCountDown] = useState();

  useEffect(() => {
    setCountDown(dataRecent?.upCommingLotteries[0]?.lotteryDrawDate)
  })
  var img = dataRecent?.upCommingLotteries[0]?.image !== undefined ?
    `${process.env.API_URL_PREFIX}/api/v1/admin/uploads/${dataRecent?.upCommingLotteries[0]?.image.replace(/\s+/g, '_')}` : topContainerData;

  console.log("counter", counter)
  var cardStyle = {
    display: "block",
    transitionDuration: "0.3s",
    //backgroundImage: `url(${dataRecent?.upCommingLotteries[0]?.image !== undefined?`https://cryptolotteryapi.dev.vinove.com/api/v1/admin/uploads/${dataRecent?.upCommingLotteries[0]?.image}`:topContainerData})`,
    //background: "#050518",
    boxShadow: "0px 4px 50px 30px rgba(181, 181, 181, 0.05)",
    borderRadius: "20px",
  };
  var cardStyle3 = {
    display: "block",
    transitionDuration: "0.3s",
    backgroundColor: "transparent",
    height: "492px",
    // background: `url(${card3})`,
    // backgroundImage: `url("https://cryptolotteryapi.dev.vinove.com/api/v1/admin/uploads/${bannerData?.bannerImage}")`,
    backgroundRepeat: "no-repeat",
    boxShadow: "0px 4px 50px 30px rgba(181, 181, 181, 0.05)",
    borderRadius: "20px",
    backgroundSize: "cover",
    // backgroundPosition:"center"
  };
  var boxCard = {
    background: "#3C37FF",
    borderRadius: "8px",
    padding: "10px 25px",
    margin: "4px"
  }
  var yellowBox = {
    padding: "16px 20px 16px 20px",
    background: "rgba(255, 220, 57, 0.15)",
    borderRadius: "14.4px"
  }
  return (
    <Grid
      container
      display={"flex"}
      direction={"row"}
      justifyContent={"flex-start"}
      alignItems={"flex-start"}
      p={{ xs: "0px", md: "0px" }}
      pt={{ xs: "50px !important" }}

    >
      <Grid item xs={12} sm={12} md={12}>
        <Grid
          container
          display={"flex"}
          direction={"row"}
          spacing={2}
          justifyContent={"center"}
          alignItems={"center"}
          marginTop={{ xs: "45px", md: "80px" }}
        >
          <Grid item xs={4} sm={4} md={4} >
            <Card style={cardStyle}
              className="backgroundsize100per"
              sx={{
                backgroundImage: `url("${process.env.API_URL_PREFIX}/api/v1/admin/uploads/${dataRecent?.firstupComRecords[0]?.image}")`,
                backgroundRepeat: "round",
                backgroundSize: { xs: "cover", md: "cover" },
                height: { sm: "250px", md: "492px", xl: "650px" },
              }}
            >
              <CardContent sx={{ marginTop: { xs: "0px", md: "280px", xl: "370px" } }}>
                <Stack spacing={2}>
                  {/* <Item> <Typography variant="body2" >
                  LOTTERY JACKPORT
          </Typography></Item>
                  <Item><Typography variant="h6"  sx={{color: "#7E7E7E",}}>
                  PLAY FOR JUST $1.00!
          </Typography></Item> */}
                  <div className="top-container-card-bg">
                    <Item sx={{ boxShadow: "none" }}><Typography variant="h6" sx={{ color: "white", fontSize: "x-large" }}>{dataRecent?.firstupComRecords[0]?.lotteryName}</Typography></Item>
                    <Item sx={{ boxShadow: "none" }}><Typography variant="h6" sx={{ color: "#7E7E7E", }}>Next Drawn in</Typography></Item>

                    <Item sx={{ boxShadow: "none" }}>
                      <Typography variant="body1">
                        {dataRecent?.upCommingLotteries && dataRecent.upCommingLotteries.length > 0 ? (
                          <Countdown date={new Date(dataRecent.upCommingLotteries[0]?.lotteryDrawDate)} />
                        ) : (
                          "There is a wait for the lottery."
                        )}
                      </Typography>
                    </Item>

                    <Item sx={{ boxShadow: "none", cursor: "pointer" }} onClick={() => window.location.assign("/auth/login-page")}><Box style={boxCard}><Typography variant="h3" sx={{ color: "#FFFBFF", }}>Buy Ticket</Typography></Box></Item>
                  </div>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          {(status && hidden) && <Grid item xs={4} sm={4} md={4}>
            <Card
              style={cardStyle}
              sx={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                backgroundColor: "#000 !important",
                height: { sm: "250px !important", md: "492px !important", xl: "650px !important" },
              }}
            >
              <CardActionArea
                sx={{
                  display: "flex",
                  flexGrow: "1",
                  border: "none",
                  margin: 0,
                  padding: 0,
                  height: "100%",
                  width: "100%",
                }}
              >
                {/* <CardMedia component="video" title="Your Title" src={mervVideo} sx={{ height: "100%", width: "100%", border: "none" }} autoplay mute/> */}

                <video
                  style={{ height: "100%", width: "100%", border: "none" }}
                  playsInline
                  autoPlay
                  loop
                  muted
                  controls
                  // alt="All the devices"
                  src={mervVideo}
                // ref={videoEl}
                />
              </CardActionArea>
            </Card>
          </Grid>}
          <Grid item xs={4} sm={4} md={4}>
            <Carousel
              className="cstm1-crousel"
              //   next={ (next, active) => 
              //   setImageUrl(`url("https://cryptolotteryapi.dev.vinove.com/api/v1/admin/uploads/${dataRecent?.firstupComRecords[0]?.image}")`)
              // }
              animation="slide"
              interval={10000}
              indicatorContainerProps={{
                style: {
                  color: "#FFFF",
                  zIndex: 1,
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "5px",
                  marginBottom: "15px"
                }

              }}

              activeIndicatorIconButtonProps={{
                style: {
                  color: "#FFFFF",
                  fill: "#ffff"
                },
              }}
            >
              {bannerData?.bannerImage.map((item, i) => (
                <Card style={cardStyle3}
                  className="backgroundsize100per"
                  sx={{
                    height: { sm: "250px !important", md: "492px !important", xl: "650px !important" },
                    backgroundImage: `url("${process.env.API_URL_PREFIX}/api/v1/admin/uploads/${item}")`,
                  }}>
                  <CardActionArea>
                    <CardContent >
                      <Grid container>
                      </Grid>
                    </CardContent>
                  </CardActionArea>
                </Card>
              ))}
            </Carousel>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TopContainer;
