import { Suspense } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';

import { MainLayout } from '@/components/layout/MainLayout';
import { Dashboard } from '@/features/Dashboard/routes/Dashboard';
import { WalletMain } from '@/features/wallet/components/wallet';
import { HistoryMain } from '@/features/History/Components/history';
import ErrorPage from '@/components/Elements/errorPage';
import BuyNow from '@/features/buyNow/component/buyNow';
import Promotions from '@/features/promotions/components/promotions';
//import LiveLotteries from '@/featuresLotteries/componentsLottery';
import UpcomingLotteries from '@/features/upcomingLotteries/component/upcomingLiveLottery';
import { Landing } from '@/features/misc';
import LiveLotteries from '@/features/liveLotteries/components/LiveLottery';
import ContactUS from '@/features/ContactUs/component/contactUs';
import ReplayLotteries from '@/features/replayLottery/component/replayLottery';
import { Ticket } from '@/features/Tickets/component/ticket';
import { FaqMain } from '@/features/Faq/routes/FaqMain';

import MainAbout from '@/features/AboutUS/Components/MainAbout';
import MyProfile from '@/features/MyProfile/components/MyProfile';
import { Account } from '@/features/AccountVerification/component/accountVerification';

import MyAccount from '@/features/MyProfile/components/MyAccount';
import PromotionsView from '@/features/promotions/components/promotionView';
import PromotionDetail from '@/features/promotions/components/promotionDetail';
import { Friends } from '@/features/Friends/Component/Friends';
import { getWalletAmount, useWalletAmount } from '@/components/Elements/api/getWalletamount';
import PromotionFreeTicket from '@/features/promotions/components/promotionFreeTkt';
import BuyFreeTicket from '@/features/buyNow/component/promotionBuyFreeTicket';
import { Swap } from '@/features/wallet/components/swap';
// import Account from '@/features/AccountVerification/component';


const App = () => {
  const navigate = useNavigate();

  return (
    <MainLayout>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">

          </div>
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};


export const protectedRoutes = [
  {
    path: '/', element: <App />,
    children: [
      { path: '/', element: <Dashboard /> },
      { path: '/app/liveLotteries/:id', element: <Landing /> },
      { path: '/app', element: <Dashboard /> },
      { path: '/app/dashboard', element: <Dashboard /> },
      { path: '/app/wallet/:valueUrl', element: <WalletMain walletPassword="nikita" /> },
      { path: '/app/history', element: <HistoryMain /> },
      { path: '/app/Lotteries/:id', element: <BuyNow /> },
      { path: '/app/BuyFreeTicket/:id', element: <BuyFreeTicket /> },
      { path: '/app/PromotionFreeTicket', element: <PromotionFreeTicket /> },
      { path: '/app/PromotionsDetail', element: <PromotionDetail /> },
      { path: '/app/PromotionsView', element: <PromotionsView /> },
      { path: '/app/liveLotteries', element: <LiveLotteries /> },
      { path: '/app/upComingLotteries', element: <UpcomingLotteries /> },
      { path: '/app/replayLottery', element: <ReplayLotteries /> },
      { path: '/app/ticket', element: <Ticket /> },
      { path: '/app/friend', element: <Friends /> },
      { path: '/app/aboutus', element: <MainAbout /> },
      { path: '/app/myaccount', element: <MyAccount /> },
      { path: '/app/accountVerify', element: <Account /> },
      { path: '/app/swap', element: <Swap /> },
      {
        path: '/myProfile',
        element: <MyProfile />,
      },
    ],
  },
  { path: '/app/*', element: <ErrorPage /> },
];