import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Paper,
  FormControl,
  InputLabel,
  Stack,
  Typography,
  Divider,
  CardMedia,
  Button,
  Icon,
  InputAdornment,
  TextField,
  ListSubheader,
  ListSubheaderProps,
  SelectChangeEvent,
  IconButton,
  CircularProgress,
} from "@mui/material";

import { Grid, Select, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { theme } from "@/components/Elements/theme";
import cryptoPayments from "@/assets/cryptoPayments.svg";
import allPayments from "@/assets/allPayments.svg";
import { usePayment } from "../api/fetchPaymentCurrency";
import axios from "axios";
import storage from "@/utils/storage";
import Swal from "sweetalert2";
import VerifyWithdrawOTP1 from "@/components/Modals/verifyWithdrawOTP";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { boolean } from "zod";

interface CoinRateItem {
  currency: string;
  paymentMethodId: string;
  image?: string; // Optional in case some items don’t have an image
}
interface FormValues {
  amount: number;
  cryptoType: string;
  walletAddress: string;
  paymentMethodId: string;
}

interface FormErrors {
  amount?: string;
  cryptoType?: string;
  walletAddress?: string;
}


interface ErrorState {
  cryptoType?: any;
  amount?: any;
  walletAddress?: any;
}

export const WithDraw = () => {
  // const {data} = usePayment({});
  const [totalWalletBal, setTotalWalletBal] = useState(0);
  const [winArray, setWinArray] = useState([
    {
      LotteryName: "",
      amount: "",
      lotteryStatus: "",
      transactionId: "",
      winAmount: "",
    },
  ]);
  const [arr, setarr] = useState([]);
  const [loading, setLoading] = useState(false);

  const [walletAddrArray, setWalletAddrArray] = useState([
    "BTC",
    "LTC",
    "XPM",
    "MAZA",
    "NMC",
    "ETH",
    "STRK",
    "XDC",
    "PPC",
    "ETC",
    "BCH",
    "DPOS",
    "TRON",
    "XTZ",
    "BSV",
    "ALGO",
    "SOL",
    "DOT",
    "AVAX",
    "MOB",
    "ICP",
    "DESO",
    "LUNC",
    "LUNA",
    "XLM",
    "EOS",
    "LBC",
    "XRP",
    "STX",
    "VTC",
    "HBAR",
    "ZEC",
    "XMR",
    "BTG",
    "DOGE",
    "MATIC",
    "BNB",
    "ETHER",
    "APT",
    "NEAR",
    "MIOTA",
    "XNO",
  ]);
  const [usdcWalletArray, setUSDCWalletArray] = useState([
    "Algorand",
    "Arbitrum",
    "Avalanche",
    "Base",
    "Celo",
    "Ethereum",
    "Flow",
    "Hedera",
    "Noble",
    "OP Mainnet",
    "Polkadot",
    "Polygon PoS",
    "Solana",
    "Stellar",
  ]);
  const [usdtWalletArray, setUSDTWalletArray] = useState([
    "Ethereum",
    "Omni",
    "Tron",
    "EOS",
    "Liquid",
    "Algorand",
  ]);
  const [busdWalletArray, setBUSDWalletArray] = useState([
    "Avalance",
    "BNB Chain",
    "Ethereum",
    "Polygon Networks",
  ]);

  // const [currencyArray, setCurrencyArray] = useState([
  //   {
  //     currency: "select",
  //     paymentMethodId: "",
  //   },
  // ]);
  const [coinRate, setcoinRate] = useState<CoinRateItem[]>([]);
  const [convertedRate, setConvertedRate] = useState("");
  const [loadingCurrency, setLoadingCurrency] = useState(false); // For the currency list
  const [loadingConvertedRate, setLoadingConvertedRate] = useState(false);
  // const [formValues, setFormValues] = useState({
  //   amount: "",
  //   coinNetwork: "select",
  //   // paymentMethodId: "",
  //   walletAddress: "",
  //   // walletNetwork: "select",
  // });

  const [formValues, setFormValues] = useState<FormValues>({
    amount: 0,
    cryptoType: "",
    walletAddress: "",
    paymentMethodId: "",
  });



  const [userData, setUserData] = useState({
    userId: "",
    emailId: "",
  });
  useEffect(() => {
    getUserData();
  }, []);
  const getUserData = async () => {
    let token = storage.getToken();
    const response = await axios.get(
      `${process.env.API_URL_PREFIX}/api/v1/user/userdetail`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    setUserData({
      userId: response?.data?.data?._id,
      emailId: response?.data?.data?.email,
    });
  };

  // const getPriceConversionWithFee = async (value, p) => {
  //   let token = storage.getToken();
  //  await axios
  //     .post(
  //       `${process.env.API_URL_PREFIX}/api/v1/user/fetchUserWithdrawReq?search=${value}`

  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Access-Control-Allow-Origin": "*",
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       setTotalRecords(res?.data?.totalExternalWallAddCount);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const [currency, setCurrency] = useState("");

  const Item = styled(Paper)(({ theme }) => ({
    background: "transparent",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  }));

  var cardStyle = {
    display: "block",
    transitionDuration: "0.3s",
    background: "#050518",
    boxShadow: "0px 4px 50px 30px rgba(181, 181, 181, 0.05)",
    borderRadius: "20px",
  };

  var center = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  var content = {
    color: "#ffff",
    backgroundColor: "#050518",
    borderRadius: "12px",
  };
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    let token = storage.getToken();

    const response = await axios.get(
      `${process.env.API_URL_PREFIX}/api/v1/user/fetchUserWalletBalance`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    const data = response.data.data.walletAmount;
    setTotalWalletBal(data);
    setWinArray(data.myLotteryArrray);
    setLoading(true);
  };

  useEffect(() => {
    const fetchCurrencyData = async () => {
      setLoadingCurrency(true);
      let token = storage.getToken();
      try {
        const currencyResponse = await axios.get(
          `${process.env.API_URL_PREFIX}/api/v1/user/fetchPaymentCurrency`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        const currencyData = currencyResponse?.data?.data;

        setcoinRate(currencyData);
      } catch (error) {
        console.error("Error fetching coin rate:", error);
      } finally {
        setLoadingCurrency(false);
      }
    };

    fetchCurrencyData();
  }, []);

  // Fetch the converted rate based on amount and coinNetwork
  useEffect(() => {
    const fetchCoinRateData = async () => {
      setLoadingConvertedRate(true);
      let token = storage.getToken();

      try {
        const convertedRateResponse = await axios.post(
          `${process.env.API_URL_PREFIX}/api/v1/user/getPriceConversionWithFee`,
          {
            amountInStableCoin: formValues.amount,
            selectedNetwork: formValues.cryptoType,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "*",
            },
          }
        );

        const convertedData = convertedRateResponse?.data;
        setConvertedRate(convertedData);
      } catch (error) {
        console.error("Error fetching coin rate:", error);
      } finally {
        setLoadingConvertedRate(false);
      }
    };

    const { amount, cryptoType } = formValues;

    if (amount && cryptoType) {
      fetchCoinRateData();
    }
  }, [formValues.amount, formValues.cryptoType]);

  const [isOpen, setIsopen] = useState(false);
  const [error, setError] = useState<ErrorState>({});
  const [isValid, setIsValid] = useState<boolean | null>(null); // Declare isValid as boolean or null initially

  const validateAmount = (amount) => {
    if (amount <= 5.99) {
      return "Amount should be greater than 5.99";
    }
    return "";
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "cryptoType") {
      const selectedCoin = coinRate.find((item) => item.currency === value);

      setFormValues({
        ...formValues,
        cryptoType: value,
        paymentMethodId: selectedCoin?.paymentMethodId || "",
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
    if (name === "amount" && !value) {
      setConvertedRate("");
    }

    if (name === "amount") {
      const amount = parseFloat(value);
      if (isNaN(amount) || amount <= 5.99) {
        setError({amount: <span style={{ fontSize: "12px", color: "red" }}>Amount should be greater than 5.99</span>
        });
      } else {
        setError({ amount: "" }); 
      }

    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const isValid = checkValidation("all"); // Validate all fields

    if (!isValid) {
      console.log("Form is invalid. Check errors.");
      setLoading(false); // Ensure loading state is off if form is invalid
      return; // Prevent further code execution if form is invalid
    }

    try {
      console.log("Form is valid. Proceed with withdrawal request.");
      setIsopen(true); // Open OTP verification modal
      setLoading(true); // Set loading state before API request

      let token = storage.getToken();
      const res = await axios.post(
        `${process.env.API_URL_PREFIX}/api/v1/user/extWalletWithdrawReqOTP`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      // Handle success if needed here

    } catch (error) {
      setLoading(false); // Stop loading if an error occurs
      Swal.fire({
        background: "black",
        color: "#ffff",
        text: 'An error occurred',
      }).then(() => {
        window.location.reload();
      });
    }
  };

  const handleClose = () => {
    setIsopen(false);
  };

  const checkValidation = (type) => {
    let isFormValid = true;
    let newError = { ...error }; // Copy existing errors

    // Validate cryptoType
    if (type === "cryptoType" || type === "all") {
      if (!formValues?.cryptoType || formValues?.cryptoType === "select") {
        newError.cryptoType = <span style={{ fontSize: "12px", color: "red" }}>Please select a cryptocurrency</span>
        
        isFormValid = false;
      } else {
        newError.cryptoType = ""; // Clear error if valid
      }
    }

    // Validate amount
    if (type === "amount" || type === "all") {
      const amount = typeof formValues?.amount === 'string' ? parseFloat(formValues?.amount) : formValues?.amount;

      if (isNaN(amount) || amount <= 5.99) {
        newError.amount =  <span style={{ fontSize: "12px", color: "red" }}>Amount should be greater than 5.99</span>
        isFormValid = false;
      } else {
        newError.amount = ""; // Clear error if valid
      }
    }

    // Validate walletAddress
    if (type === "walletAddress" || type === "all") {
      if (!formValues?.walletAddress) {
        newError.walletAddress = <span style={{ fontSize: "12px", color: "red" }}>Please select a cryptocurrency</span>
        isFormValid = false;
      } else {
        newError.walletAddress = ""; // Clear error if valid
      }
    }

    setError(newError);
    return isFormValid;
  };


  const handleBlur = () => {
    checkValidation('cryptoType'); // Trigger validation on blur
  };


  const validateWalletAddressForPayment = async (walletAddress) => {
    try {

      const response = await axios.post(`${process.env.API_URL_PREFIX}/api/v1/user/isValidAddressForPayment`, {
        address: walletAddress,
        network: formValues.cryptoType,
      });
      if (response.data.isValid) {
        setIsValid(true);
        setError((prev) => ({ ...prev, walletAddress: "" }));
      } else {
        setIsValid(false);
        setError((prev) => ({ ...prev, walletAddress: "Invalid wallet address" }));
      }
    } catch (error) {
      console.error("API error:", error);
      setIsValid(false);
      setError((prev) => ({ ...prev, walletAddress: "Validation failed" }));
    }
  };
  useEffect(() => {
    if (formValues.walletAddress) {
      validateWalletAddressForPayment(formValues.walletAddress);
    }
  }, [formValues.walletAddress]); // This runs whenever walletAddress changes


  const handlePaste = (e) => {
    e.preventDefault();
    const pastedAddress = e.clipboardData.getData("Text"); // Get the pasted value
    setFormValues((prev) => ({ ...prev, walletAddress: pastedAddress })); // Update state
    validateWalletAddressForPayment(pastedAddress); // Call the validation API
  };

  return (
    <Box>
      <form onSubmit={handleFormSubmit}>
        {/* <form> */}
        <Grid container sx={{ mt: theme.spacing(0.5) }}>
          <Grid item xs={12} md={6}>
            {" "}
            <Card
              style={cardStyle}
              sx={{ p: theme.spacing(0.3), m: theme.spacing(0.2) }}
            >
              <CardContent>
                <Stack spacing={2}>
                  <Item>
                    {" "}
                    <Typography variant="h5">Withdraw</Typography>
                  </Item>
                  <Item>
                    <Typography
                      variant="h6"
                      sx={{ color: "#7E7E7E", textTransform: "uppercase" }}
                    >
                      Select Payment System
                    </Typography>
                  </Item>
                  <Divider
                    sx={{ border: "1px solid rgba(255, 255, 255, 0.2)" }}
                  />
                  <Item className="Select-Payment-System">
                    <Grid item xs={12} sm={6} md={12}>
                      <Card
                        style={content}
                        sx={{
                          background: "rgba(255, 255, 255, 0.03) !important",
                          display: "flex",
                        }}
                        className={"activePayment"}
                      >
                        <CardMedia
                          component="img"
                          sx={{
                            borderRadius: "10px",
                            p: theme.spacing(0.3),
                            width: "150.06px",
                            height: "129.68px",
                          }}
                          image={cryptoPayments}
                          alt="Live from space album cover"
                        />
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <CardContent sx={{ flex: "1 0 auto", p: "0", mt: 2 }}>
                            <Stack spacing={2} flex="1 0 1">
                              <Box>
                                <Typography component="h6" variant="h6">
                                  Crypto Payment
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  component="h3"
                                  variant="h3"
                                  sx={{ color: "#A3A9AD" }}
                                >
                                  Minimum - 5(USD) | Maximum - 1,000,000(USD)
                                </Typography>
                              </Box>

                              <Box>
                                <Button
                                  sx={{
                                    px: "19px",
                                    py: "7.73px",
                                    marginBottom: "19px !important",
                                    backgroundColor: "rgba(60, 55, 255, 0.15)",
                                    color: "#3C37FF",
                                    borderRadius: "19px",
                                  }}
                                >
                                  Payments.Commision - 0
                                </Button>
                              </Box>
                            </Stack>
                          </CardContent>
                        </Box>
                      </Card>
                    </Grid>
                  </Item>
                </Stack>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            {" "}
            <Card
              style={cardStyle}
              sx={{ p: theme.spacing(0.2), m: theme.spacing(0.2) }}
            >
              <CardContent>
                <Stack spacing={1}>
                  <Item sx={{ display: "contents" }}>
                    {" "}
                    <Typography variant="h5">
                      Select Withdrawal Amount
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ color: "#7E7E7E", textTransform: "uppercase" }}
                    >
                      Enter details to proceed
                    </Typography>
                  </Item>
                  <Divider
                    sx={{ border: "1px solid rgba(255, 255, 255, 0.2)" }}
                  />
                  <Item>
                    <Grid item xs={12} md={12}>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          p: 1,
                          m: 1,
                          bgcolor: "transparent",
                          border: "0.5px solid rgba(255, 255, 255, 0.2)",
                          borderRadius: "10px",
                          opacity: 0.6,
                        }}
                      >
                        <Box sx={{ p: "15px", flex: "30%" }}>
                          <Typography component="h6" variant="h6">
                            Total Available
                          </Typography>
                        </Box>

                        <Box
                          sx={{ p: "15px", flex: "30%", textAlign: "right" }}
                        >
                          <Typography component="h6" variant="h6">
                            $ {totalWalletBal}
                          </Typography>
                        </Box>
                        <Box sx={{ p: "15px", flex: "100%" }}>
                          <Divider
                            orientation="horizontal"
                            flexItem
                            sx={{
                              border: "1px solid rgba(255, 255, 255, 0.2)",
                            }}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Item>

                  <Typography
                    variant="h6"
                    sx={{ display: "block", mt: 2, color: "blue" }}
                  >
                    Select Crypto-Currency
                  </Typography>
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      onChange={(e) => {
                        handleChange(e);

                      }}
                      onBlur={handleBlur}
                      defaultValue="select"
                      name="cryptoType"
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            bgcolor: "black",
                            "& .MuiMenuItem-root": {
                              padding: 1,
                              fontFamily: "Ubuntu",
                              fontStyle: "normal",
                              fontWeight: 400,
                              fontSize: "14px",
                            },
                          },
                        },
                      }}
                    >
                      <MenuItem disabled value="select">
                        <em>Select</em>
                      </MenuItem>

                      {loadingCurrency ? (
                        <MenuItem disabled>
                          <CircularProgress size={24} />
                          <Typography ml={2}>Loading...</Typography>
                        </MenuItem>
                      ) : (
                        coinRate.map((item) => (
                          <MenuItem
                            key={item.paymentMethodId}
                            value={item.currency}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                                width: "100%",
                              }}
                            >
                              {item.image && (
                                <img
                                  src={item?.image}
                                  alt={item.currency}
                                  style={{ width: 24 }}
                                />
                              )}
                              <Typography
                                textAlign="left"
                                sx={{ fontSize: "20px" }}
                              >
                                {item.currency}
                              </Typography>
                            </Box>
                          </MenuItem>
                        ))
                      )}
                    </Select>
                    {error?.cryptoType && <Typography sx={{ color: "red" }}>{error.cryptoType}</Typography>}
                  </FormControl>

                  <Typography
                    variant="h6"
                    sx={{ display: "block", mt: 2, color: "blue" }}
                  >
                    Amount
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mt: 2,
                    }}
                  >
                    <FormControl sx={{ flex: 1, mr: 1 }}>
                      <TextField
                        id="outlined-multiline-flexible"
                        type="number"
                        placeholder="Enter Withdrawal Amount"
                        maxRows={4}
                        value={formValues?.amount || ""}
                        name="amount"
                        onChange={handleChange}
                        InputProps={{
                          endAdornment:
                            formValues?.cryptoType &&
                              coinRate[formValues.cryptoType]?.image ? (
                              <InputAdornment position="end">
                                <img
                                  src={coinRate[formValues.cryptoType]?.image}
                                  alt={formValues?.cryptoType}
                                  style={{ width: 24 }}
                                />
                              </InputAdornment>
                            ) : null,
                          sx: {
                            "input::-webkit-inner-spin-button": {
                              WebkitAppearance: "none",
                              margin: 0,
                            },
                            "input::-webkit-outer-spin-button": {
                              WebkitAppearance: "none",
                              margin: 0,
                            },
                            "input[type=number]": {
                              "-moz-appearance": "textfield",
                            },
                          },
                        }}
                        inputProps={{
                          min: "1", // Minimum value is 1
                          step: "1", // Step value is 1
                          pattern: "[0-9]*", // Only allow numeric input
                        }}
                      />
                      {error?.amount && <Typography sx={{ color: "red" }}>{error.amount}</Typography>}

                    </FormControl>

                    <Box sx={{ display: "flex", alignItems: "center", mx: 2 }}>
                      <IconButton aria-label="backward" color="primary">
                        <ArrowBackIcon />
                      </IconButton>
                      <IconButton aria-label="forward" color="primary">
                        <ArrowForwardIcon />
                      </IconButton>
                    </Box>

                    <FormControl sx={{ flex: 1, ml: 1 }}>
                      <TextField
                        id="converted-amount"
                        placeholder="Converted Amount"
                        multiline
                        maxRows={4}
                        value={
                          loadingConvertedRate ? "" : "$" + " " + convertedRate
                        } // Show empty string when loading
                        name="convertedAmount"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {loadingConvertedRate ? (
                                <CircularProgress size={20} /> // Show spinner in the adornment
                              ) : (
                                "BTC"
                              )}
                            </InputAdornment>
                          ),
                          sx: {
                            "input::-webkit-inner-spin-button": {
                              WebkitAppearance: "none",
                              margin: 0,
                            },
                            "input::-webkit-outer-spin-button": {
                              WebkitAppearance: "none",
                              margin: 0,
                            },
                            "input[type=number]": {
                              "-moz-appearance": "textfield",
                            },
                          },
                          readOnly: true,
                        }}
                      />

                    </FormControl>
                  </Box>

                  <Typography
                    variant="h6"
                    sx={{ display: "block", mt: 2, color: "blue" }}
                  >
                    External Wallet Address
                  </Typography>
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <TextField
                      style={{ width: "100%", marginLeft: "5px" }}
                      id="outlined-multiline-flexible"
                      placeholder="Enter External Wallet Address"
                      multiline
                      maxRows={4}
                      // InputProps={{
                      //   endAdornment: (
                      //     <InputAdornment position="end">
                      //       <PermIdentityIcon style={{ color: "white" }} />
                      //     </InputAdornment>
                      //   ),
                      // }}
                      value={formValues?.walletAddress}
                      name="walletAddress"
                      onChange={handleChange}
                      onPaste={handlePaste}
                    />
                    {error?.walletAddress && <Typography sx={{ color: "red" }}>{error.walletAddress}</Typography>}
                    {isValid === false && <Typography sx={{ color: "red" }}>Wallet address is invalid</Typography>}
                  </FormControl>

                  <Item>
                    <Grid xs={12} md={12}>
                      {" "}
                      <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} type="submit">
                        Withdraw Request
                      </Button>
                    </Grid>
                  </Item>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <VerifyWithdrawOTP1 open={isOpen} onClose={() => setIsopen(false)} formValues={formValues} userData={userData} />

      </form>
    </Box>
  );
};
