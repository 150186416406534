import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import { Wheel } from "../Wheel";
import { postLotteryDraw } from "../api/lotteryDrawId";
import { useLotteryTicket } from "../api/lotteryTicketList";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Particles from "react-tsparticles";
import { loadFireworksPreset } from "tsparticles-preset-fireworks";
import yellowt from "@/assets/yellow-t.png";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeDownIcon from "@mui/icons-material/VolumeDown";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import Countdown from 'react-countdown';
import { loadFull } from "tsparticles";
import CurrencyFormat from 'react-currency-format';
import Confetti from "react-confetti";
import './animation.css';
import {
  Divider,
  Modal,
  Grid,
  Paper,
  Table,
  TableBody,
  Button,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import CountUp from "react-countup";
import arrow from "@/assets/arrow.png";
import ad from "@/assets/add.svg";
import useWindowSize from "react-use/lib/useWindowSize";
//import logo from '@/assets/logo.svg';
// import { Button } from '@/components/Elements';
// import { Head } from '@/components/Head';
import { useAuth } from "../../../lib/auth";
import { Box, createStyles } from "@mui/material";
import sound from "../../../assets/carnival (1).mp3";
import number_sound from "../../../assets/number_appearing.mp3";
import whitet from "@/assets/white-t.png";
import axios from "axios";
import "./wheel.css";
import { TypeOf, any } from "zod";
import { TopHeadingLayout } from "@/components/layout/TopHeadingLayout";
import storage from "@/utils/storage";
import { lotteryData } from "@/features/buyNow/types";
import { BorderRight, ImportantDevices, ScatterPlot } from "@material-ui/icons";
import { isVisible } from "@testing-library/user-event/dist/utils";
import { useParams } from "react-router-dom";
import lotteryFrame from "@/assets/spinnerFrame.png";
import lotteryName from "@/assets/mervIcon.svg";
import rpm from "@/assets/rpmSpinner.svg";
import speed from "@/assets/speedSpinner.svg";
import WinningHistoryWheel from "./winningHistory";
import WinModal from "./winModal";
import LostModal from "./lostModal";
import PrizeModal from "./prizeModal";
import LotteryPoolModal from "./lotteryPoolModal";
import { yellow, blue, red, orange, green, pink, purple } from '@mui/material/colors';
import JackpotModal from "./jackpotModal";
import JackpotWinModal from "./jackpotWinningModal";
import JackpotLostModal from "./jackpotLostModal";



interface SelectedColorData {
  count: number;
  selectedJackpotColourNumber: string;
}

interface LotteryTicketColour {
  message: string;
  data: SelectedColorData[];
}


interface SpinningBallData {
  _id: string;
  endDate: string;
  endTime: string;
  lotteryDrawDate: string;
  randomGeneratedBall: number;
  availableBalls: number[];
}

interface BallPosition {
  number: number;
  left: string;
}

interface Color {
  light: string;
  dark: string;
}

interface LotteryDataType {
  lotteryDrawDate?: string;
  lotteryDrawTime?: string;
  totalDraw?: number;

  jackpotSelected?: boolean

}


interface JackpotWinningData {
  message?: string;
  data: {
    WinningAmount: number;
    jackpotStatus: boolean;
    jackpotRound: number;
    getJackpotWinTicket: string;
  };
}

// interface JackpotWinningData1 {
//   message: string;
//   data: JackpotWinningData;

// }

interface winningList {
  lotteryStatus?: string
}

interface winningList1 {

  data: winningList;

}


interface dataLottery {
  data: any;

}

const dataValue = {
  heading: "",
  subHeading: "",
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  leftPosition: {
    [theme.breakpoints.down("sm")]: {
      left: "16% ! important",
    },
  },
  boxShadow: {
    "-webkit-box-shadow": "1px 4px 9px 1px rgba(59,151,187,1)",
    "-moz-box-shadow": "1px 4px 9px 1px rgba(59,151,187,1)",
    "box-shadow": "1px 4px 9px 1px rgba(59,151,187,1)",
    borderRight: "4px solid #3b97bb",
  },
  paperClass: {
    padding: theme.spacing(2),
    textAlign: "center",
    background: "rgba(5, 5, 24, 0.8)",
    borderRadius: "10px",
  },
  first: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: 467,
  },
  second: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: 150,
  },
  parentPaper: {
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 1600,
  },
  availableTickets: {
    border: "0.5px solid rgba(255, 255, 255, 0.2)",
    borderRadius: "10px",
    height: 110,
  },
  noOfTickets: {
    background: "rgba(5, 5, 24, 0.8)",
    borderRadius: "10px",
    height: 180,
  },
  tableContainer: {
    background: "#050518",
    borderRadius: 8,
    p: { xs: "0px !important", md: "25px" },
  },
  table: {
    maxWidth: "280",
  },
  tableHeader: {
    color: "#fff",
  },
  tableData: {
    fontSize: "14px",
    color: "#fff",
    opacity: "0.6",
    borderBottom: "1px solid #0D0D39",
  },
  minimumTicket: {
    background: "#3C37FF",
    borderRadius: "10px",
    color: "#fffff",
  },
  logo: {
    width: 50,
  },
  myGridItem: {
    backgroundColor: "rgba(5, 5, 24, 0.8)",
    padding: "30px",
    borderRadius: "20px",
  },
  innerGrid: {
    backgroundColor: "#050518",
    padding: "10px",
    borderRadius: "20px",
  },
  innerGridFirst: {
    backgroundRepeat: "no-repeat",
    height: "auto",
    width: "auto",
    minHeight: "282px",
    minWidth: "282px",
    backgroundPosition: "left bottom",
    [theme.breakpoints.down("sm")]: {
      minHeight: "200px",
      minWidth: "200px",
    },
  },
  substract: {
    border: "1px solid #ffff !important",
    borderRadius: "10px !important",
    color: "#ffff",
  },
  innerGridSecond: {
    backgroundColor: "#050518",
    padding: "10px",
    borderRadius: "20px",
  },
  ticketPrize: {
    background: "rgba(5, 5, 24, 0.8)",
    borderRadius: "10px",
    padding: "30px",
  },
  ticketContainer: {
    width: "119px",
    height: "60px",
    // background: "#FFFF",
    borderRadius: "10px",

    position: "relative",
  },
  detailContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "14px",
    padding: "12.86px",
  },
  detailLabel: {
    height: "11px",
    fontFamily: "Ubuntu",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "10px",
    lineHeight: "11px",
    // color: "rgba(27, 28, 45, 0.6)",
    color: "black",
    margin: "0",
  },
  detailNumber: {
    height: "23px",
    fontFamily: "Ubuntu",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20.3636px",
    lineHeight: "23px",
    letterSpacing: "0.1em",
    textTransform: "uppercase",
    color: "black",
    margin: "0",
  },
  detailTime: {
    width: "82px",
    height: "16px",
    fontFamily: "Ubuntu",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#1B1C2D",
    margin: "0",
  },
  list: {
    overflowY: "auto",
    margin: 0,
    padding: 0,
    listStyle: "none",
    //height: "100%",
    maxHeight: "300px",
    // height: "270px",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 2px 2px 5px 0 rgba(#fff, 0.5)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundImage: "linear-gradient(180deg, #3b97bb 0%, #aed1db 99%)",
      boxShadow: "inset 2px 2px 5px 0 rgba(#fff, 0.5)",
      borderRadius: "100px",
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: "200px",
    },
  },
  jackpotContainer: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
    height: "38px",
    background: "rgba(60, 55, 255, 0.15)",
    marginBottom: "14px",
  },
  jackpotText: {
    width: "99px",
    height: "23px",
    fontFamily: "Ubuntu",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20.3636px",
    lineHeight: "23px",
    letterSpacing: "0.2em",
    textTransform: "uppercase",
    color: "#27272F",
    margin: "auto",
  },
  arrowImage: {
    position: "absolute",
    width: "30px",
    height: "30px",
    left: "74%",
    transform: "translateX(-50%)",
  },
  polyup: {
    top: "-15px",
    left: "86%",
  },
  polydown: {
    bottom: "-15px",
    transform: "rotate(180deg)",
  },
  ballRow: {
    display: 'flex',
    alignItems: 'center',
    // width: `${5 * 45 + 10}px`,

    overflow: 'hidden',
    position: 'relative',
    height: '60px',
    boxShadow: "1px 4px 9px 1px rgba(59,151,187,1) !important",
    border: " 4px solid #3b97bb",
    borderRadius: "12px",
    margin: "auto",
    // justifyContent: 'center',
  },
  ball: {
    minWidth: '40px',
    width: "40px",
    height: "40px",
    margin: '0px 5px 0px -1px',
    fontSize: 22,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    borderRadius: '50%',
    // position: 'absolute',
    border: '2px solid #000',
    // marginLeft: "-3px",
    '&:hover': {
      transform: 'scale(1.2)',

    },
  },
  spinning: {
    animation: `$move 2s linear infinite`,
  },
  '@keyframes move': {
    '0%': { transform: 'translateX(100%)' },
    '100%': { transform: 'translateX(-1000%)' },
  },
  winnerBall: {
    border: '4px solid white',
    animation: 'none',
    zIndex: 99,
    height: "42px",
    width: "42px",
    boxShadow: "0 0 10px rgb(255 255 255 / 100%) !important"
  },
  progressBollBoxOuter: {
    position: "relative",
    minHeight: "120px",
    marginRight: "30px"
  },
  progressBollBox: {
    width: "100%",
    "& canvas": {
      width: "100% !important",
      height: "120px !important",
    },
  }

}));




const data1 = [
  { option: "0", style: { textColor: "#FFFF" } },
  { option: "1", style: { textColor: "#FFFF" } },
  { option: "2", style: { textColor: "#FFFF" } },
  { option: "3", style: { textColor: "#FFFF" } },
  { option: "4", style: { textColor: "#FFFF" } },
  { option: "0", style: { textColor: "#FFFF" } },
  { option: "1", style: { textColor: "#FFFF" } },
  { option: "2", style: { textColor: "#FFFF" } },
  { option: "3", style: { textColor: "#FFFF" } },
  { option: "4", style: { textColor: "#FFFF" } },
];

const data2 = [
  { option: "0", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "1", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "2", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "3", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "4", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "5", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "6", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "7", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "8", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "9", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
];
const data3 = [
  {
    option: "0",
    style: { textColor: "rgba(255,255,255,1.00)", fontWeight: "bold" },
  },
  {
    option: "1",
    style: { textColor: "rgba(255,255,255,1.00)", fontWeight: "bold" },
  },
  {
    option: "2",
    style: { textColor: "rgba(255,255,255,1.00)", fontWeight: "bold" },
  },
  {
    option: "3",
    style: { textColor: "rgba(255,255,255,1.00)", fontWeight: "bold" },
  },
  {
    option: "4",
    style: { textColor: "rgba(255,255,255,1.00)", fontWeight: "bold" },
  },
  {
    option: "5",
    style: { textColor: "rgba(255,255,255,1.00)", fontWeight: "bold" },
  },
  {
    option: "6",
    style: { textColor: "rgba(255,255,255,1.00)", fontWeight: "bold" },
  },
  {
    option: "7",
    style: { textColor: "rgba(255,255,255,1.00)", fontWeight: "bold" },
  },
  {
    option: "8",
    style: { textColor: "rgba(255,255,255,1.00)", fontWeight: "bold" },
  },
  {
    option: "9",
    style: { textColor: "rgba(255,255,255,1.00)", fontWeight: "bold" },
  },
];

const data4 = [
  { option: "0", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "1", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "2", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "3", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "4", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "5", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "6", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "7", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "8", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "9", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
];
const data5 = [
  {
    option: "0",
    style: { textColor: "rgba(255,255,255,1.00)", fontWeight: "bold" },
  },
  {
    option: "1",
    style: { textColor: "rgba(255,255,255,1.00)", fontWeight: "bold" },
  },
  {
    option: "2",
    style: { textColor: "rgba(255,255,255,1.00)", fontWeight: "bold" },
  },
  {
    option: "3",
    style: { textColor: "rgba(255,255,255,1.00)", fontWeight: "bold" },
  },
  {
    option: "4",
    style: { textColor: "rgba(255,255,255,1.00)", fontWeight: "bold" },
  },
  {
    option: "5",
    style: { textColor: "rgba(255,255,255,1.00)", fontWeight: "bold" },
  },
  {
    option: "6",
    style: { textColor: "rgba(255,255,255,1.00)", fontWeight: "bold" },
  },
  {
    option: "7",
    style: { textColor: "rgba(255,255,255,1.00)", fontWeight: "bold" },
  },
  {
    option: "8",
    style: { textColor: "rgba(255,255,255,1.00)", fontWeight: "bold" },
  },
  {
    option: "9",
    style: { textColor: "rgba(255,255,255,1.00)", fontWeight: "bold" },
  },
];
const data6 = [
  { option: "0", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "1", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "2", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "3", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "4", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "5", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "6", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "7", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "8", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "9", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
];

const backgroundColors = [
  "#E40303",
  "#750787",
  "#1C6CF2",
  "#008026",
  "#FF8C00",
];
const textColors = ["#FFFFF"];
const outerBorderColor = "#eeeee";
const outerBorderWidth = 2;
const outerBorderWidth4 = 6;

const innerBorderColor = "#30261a";
const innerBorderWidth = 2;
const innerRadius = 0;
const radiusLineColor = "#E40303";
const radiusLineWidth = 0;
const fontFamily = "Nunito";
const fontSize1 = 15;
const fontSize2 = 26;
const fontSize3 = 30;
const fontSize4 = 38;
const fontSize5 = 48;
const fontSize6 = 60;
const textDistance1 = 92;
const textDistance2 = 92;
const textDistance3 = 93;
const textDistance4 = 92;
const textDistance5 = 94;
const textDistance6 = 90;

//  distance = π*diameter * number of revolutions

//  number of revolutions = distance/(π*diameter)

// const spinDuration1 = 2;
// const spinDuration2 = ;
// const spinDuration3 = 0.5;
// const spinDuration4 = 0.5;
// const spinDuration5 = 0.5;
// const spinDuration6 = 0.5;

const fontWeight = "bold";
let startDraw = false;
let jackpotStart = false;
let demoCount = 0;
let conditionalPopup = true


export const Landing = (_DrawWheelPropsData: any) => {
  var result = new Date();
  var targetDate = result.setDate(result.getDate() + 2);
  const countDownDate = new Date(targetDate).getTime();
  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getDate()
  );
  const { user } = useAuth();


  const { id } = useParams();
  const [isWinOpen, setIsWinopen] = React.useState(false);
  const [isJackpotWinOpen, setIsJackpotWinopen] = React.useState(false);

  const [isLostOpen, setIsLostopen] = React.useState(false);

  const [isJackpotLostOpen, setIsJackpotLostopen] = React.useState(false);
  const [isPrizeOpen, setIsPrizeOpen] = React.useState(false);
  const [dataLottery, setDataLottery] = React.useState<dataLottery | null>(null);
  const navigate = useNavigate();
  const [WinnerArray, setWinnerArray] = useState<any[]>([]);
  const [startingOptionIndex, setstartingOptionIndex] = useState(0);
  const [playSound, setPlaySound] = useState(false);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [isVisiblePrize1, setIsVisiblePrize1] = useState(false);

  const [isVisiblePrize2, setIsVisiblePrize2] = useState(false);
  const [isVisiblePrize3, setIsVisiblePrize3] = useState(false);
  const [isVisiblePrize4, setIsVisiblePrize4] = useState(false);

  const [isVisiblePrize5, setIsVisiblePrize5] = useState(false);
  const [isVisiblePrize6, setIsVisiblePrize6] = useState(false);
  const [avgSpeed, setAvgSpeed] = useState(0);
  const [lotterySpin, setLotterySpin] = useState([]);
  const [decelaration, setDecelaration] = useState(0);
  const [isSpinDone, setIsSpinDone] = useState(false);
  const [message, setMessage] = useState(
    "Sorry, you did not win. Fingers crossed for the next Spin."
  );
  const [lotteryNameData, setLotteryName] = useState("");
  const [lotteryImage, setLotteryImage] = useState("");
  const [spinDuration1, setSpinDuration1] = useState(0);
  const [spinDuration2, setSpinDuration2] = useState(0);
  const [spinDuration3, setSpinDuration3] = useState(0);
  const [spinDuration4, setSpinDuration4] = useState(0);
  const [spinDuration5, setSpinDuration5] = useState(0);
  const [spinDuration6, setSpinDuration6] = useState(0);

  const [winnerWithTicket, setWinnerWithTicket] = useState(false);
  const [lotteryPool, setLotteryPool] = useState(false);

  const [noOfTicketsSold, setNoOfTicketsSold] = useState(0);
  const [percentageOfTicketSold, setPercentageOfTicketSold] = useState(0);
  const [totalPrizeOfTicket, setTotalPrizeOfTicket] = useState(0);
  const [spinningBallData, setSpinningBallData] = useState<SpinningBallData | null>(null);
  const [isSpinning, setIsSpinning] = useState(false);
  const [spinningArray, setSpinningArray] = useState(false);
  const [randomBall, setRandomBall] = useState<number | null>(null);
  const [ballPositions, setBallPositions] = useState<BallPosition[]>([]);

  const [jackpotWinningData, setJackpotWinningData] = useState<JackpotWinningData | null>(null);

  const [LotteryData, setLotteryData] = useState<LotteryDataType | null>(null);
  const [showjackpotModal, setJackpotShowModal] = useState(false);
  const [selectedBall, setSelectedBall] = useState<Color[]>([]);
  const [numbers, setNumbers] = useState<number[]>([]);
  const [userData, setUserData] = useState("");
  const [lotteryTicketColour, setLotteryTiccketColour] = useState<LotteryTicketColour | null>(null);
  const [isWinnerBallHighlighted, setIsWinnerBallHighlighted] = useState(false);
  const [ballWidth, setBallWidth] = useState(0);
  const jackpotWinnerArray = jackpotWinningData?.data?.getJackpotWinTicket

  // const [jackpotWinningTicketData, setJackpotWinningTicketData] = useState<JackpotWinningData | null>(null);
  const jackpotSelected = LotteryData?.jackpotSelected

  useEffect(() => {
    setSpinDuration2((prev) => spinDuration1 - 0.3);
  }, [spinDuration1, avgSpeed]);

  useEffect(() => {
    setSpinDuration3((prev) => spinDuration2 - 0.3);
  }, [spinDuration2, avgSpeed]);

  useEffect(() => {
    setSpinDuration4((prev) => spinDuration3 - 0.3);
  }, [spinDuration3, avgSpeed]);

  useEffect(() => {
    setSpinDuration5((prev) => spinDuration4 - 0.3);
  }, [spinDuration4, avgSpeed]);

  useEffect(() => {
    setSpinDuration6((prev) => spinDuration5 - 0.3);
  }, [spinDuration5, avgSpeed]);

  const { width, height } = useWindowSize();

  const particlesInit = useCallback(async (engine) => {
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    // this adds the preset to tsParticles, you can safely use the
    await loadFireworksPreset(engine);
    //loadSlim(engine);
  }, []);

  // const particlesLoaded = useCallback(async (container) => {
  //   console.log(container);
  // }, []);



  const audioElement = useRef<HTMLAudioElement | null>(null);
  const [audio] = useState(new Audio(number_sound));

  // const [playing, setPlaying] = useState(false);

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  useEffect(() => {
    if (audioElement.current) {
      if (isMuted) {
        setIsAudioPlaying(false); // Unmute and set volume to 50%
        setPlaySound(false);
        audioElement?.current?.pause();
      } else {
        audioElement?.current?.play();
        // audioElement.loop = true;
        setIsAudioPlaying(true); // Mute
        setPlaySound(true);
      }
    }
  }, [isMuted]);

  const handleWinClose = () => {
    setIsWinopen(false);
  };

  const handleJackpotWinClose = () => {
    setIsJackpotWinopen(false);
  };

  const handleLostClose = () => {

    setIsLostopen(false);
  };



  const handleJackpotLostClose = () => {

    setIsJackpotLostopen(false);


  };


  const handleLotteryPoolClose = () => {
    setLotteryPool(false);
  };

  const handlePrizeClose = () => {
    setIsPrizeOpen(false);
  };


  const handleJackpotModalClose = () => {
    setJackpotShowModal(false);
  };

  useEffect(() => {
    const getLotteryData = async () => {
      let token = storage.getToken();

      try {
        const res = await axios.get(
          `${process.env.API_URL_PREFIX}/api/v1/user/lotteryDetailById/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        setLotteryData(res.data.data);

      } catch (err) {
        console.log(err);
      }
    };

    getLotteryData();
  }, [id]);


  useEffect(() => {
    let token = storage.getToken();
    const fetchLotteryData = async () => {
      try {
        const response = await axios.get(
          `${process.env.API_URL_PREFIX}/api/v1/user/drawlist/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        setLotterySpin(response.data.drawPrize);
        setLotteryName(response.data.lotteryName);
        setLotteryImage(response.data.lotteryImage);
        //setWinnerArray(response.data.data.lotteryDrawTktNum);
      } catch (error) {
        console.error(error);
      }
    };

    fetchLotteryData();
  }, [id]);


  const getData = async () => {
    let token = storage.getToken();
    // let formData
    // useLotteryTicketList({lotteryId:"649d4943c0a0d39915560799"})
    await axios
      .get(
        `${process.env.API_URL_PREFIX}/api/v1/user/getLotteryWinnersTicket/` + id,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        console.log(res)
      })

      .catch((err) => {
        console.log(err);
      });


    // await axios
    //   .get(
    //     `${process.env.API_URL_PREFIX}/api/v1/user/drawlist/` + id,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //         "Access-Control-Allow-Origin": "*",
    //       },
    //     }
    //   )
    //   .then((res) => {
    //     setLotterySpin(res.data.drawPrize);
    //     setLotteryName(res.data.lotteryName);
    //     setLotteryImage(res.data.lotteryImage);
    //     //setWinnerArray(res.data.data.lotteryDrawTktNum);
    //   })

    //   .catch((err) => {
    //     console.log(err);
    //   });

    await axios
      .get(
        `${process.env.API_URL_PREFIX}/api/v1/user/lottery/` + id,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        // if (res.data.data.lotteryDrawTktNumStore.length == 0 || res?.data?.data?.lotteryDrawTktNum.length==0){
        //   getData()
        // }
        if (res?.data?.data?.lotteryDrawTktNum.length) {
          setWinnerArray(res.data.data.lotteryDrawTktNum);
        } else {
          setWinnerArray(res.data.data.lotteryDrawTktNumStore);
        }
      })

      .catch((err) => {
        console.log(err);
      });




  };




  const getRPMData = async () => {
    let token = storage.getToken();

    await axios
      .get(
        `${process.env.API_URL_PREFIX}/api/v1/user/rpmAndbreakAverage/` +
        id,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        setAvgSpeed(res.data.avgSpeed);
        setDecelaration(res.data.deceleration);
        //setWinnerArray(res.data.data.lotteryDrawTktNum);
      })

      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const getBallData = async () => {
      let token = storage.getToken();

      try {
        const res = await axios.get(
          `${process.env.API_URL_PREFIX}/api/v1/admin/fetchSpinningBalls/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        setSpinningBallData(res.data.data[0]);

      } catch (err) {
        console.log(err);
      }
    };

    getBallData();
  }, [id]);

  const colors = [
    { light: yellow[700], dark: yellow[900] },
    { light: blue[700], dark: blue[900] },
    { light: red[700], dark: red[900] },
    { light: pink[900], dark: pink[800] },
    { light: orange[700], dark: orange[900] },
    { light: green[700], dark: green[900] },
    { light: purple[700], dark: purple[900] },
  ];

  // useEffect(() => {
  //   if (spinningBallData && Array.isArray(spinningBallData.availableBalls)) {
  //     const ballsToDisplay = [...spinningBallData.availableBalls];
  //     console.log(ballsToDisplay , 'ballsToDisplayballsToDisplay')

  //     if (
  //       spinningBallData.randomGeneratedBall !== null &&
  //       spinningBallData.randomGeneratedBall !== undefined &&
  //       !ballsToDisplay.includes(spinningBallData.randomGeneratedBall)
  //     ) {
  //       ballsToDisplay.push(spinningBallData.randomGeneratedBall);
  //     }

  //     ballsToDisplay.sort((a, b) => a - b);
  //     console.log(ballsToDisplay , 'ballsToDisplayballsToDisplayabhay')
  //     console.log(spinningBallData.randomGeneratedBall , "winnig ball")
  //     setBallPositions(
  //       ballsToDisplay.map((number, index) => ({
  //         number,
  //         left: `${index * (100 / ballsToDisplay.length)}%`,
  //       }))
  //     );
  //   }
  // }, [spinningBallData, spinningBallData?.randomGeneratedBall]);


  useEffect(() => {
    if (spinningBallData && Array.isArray(spinningBallData.availableBalls)) {
      let ballsToDisplay = [...spinningBallData.availableBalls];

      if (
        spinningBallData.randomGeneratedBall !== null &&
        spinningBallData.randomGeneratedBall !== undefined &&
        !ballsToDisplay.includes(spinningBallData.randomGeneratedBall)
      ) {
        ballsToDisplay.push(spinningBallData.randomGeneratedBall);
      }

      ballsToDisplay.sort((a, b) => a - b);

      setBallWidth(ballsToDisplay.length)
      // document.documentElement.style.setProperty('--setBallLength', ballsToDisplay.length.toString());



      // Repeat the ballsToDisplay array three times
      const repeatedBalls = Array(3)
        .fill(ballsToDisplay)
        .flat();

      // Set ball positions based on the repeated array
      setBallPositions(
        repeatedBalls.map((number, index) => ({
          number,
          left: `${index * (100 / repeatedBalls.length)}%`,
        }))
      );

      if (spinningArray === true) {
        stopAnimationAndCenterBall(ballsToDisplay, spinningBallData.randomGeneratedBall);
      }
    }
  }, [spinningBallData, spinningBallData?.randomGeneratedBall, spinningArray]);


  const stopAnimationAndCenterBall = (balls, winningBall) => {
    const midIndex = Math.floor(balls.length / 2);
    const winningBallIndex = balls.indexOf(winningBall);

    if (winningBallIndex !== midIndex) {
      balls.splice(winningBallIndex, 1); // Remove the winning ball from its current position
      balls.splice(midIndex, 0, winningBall); // Insert the winning ball at the middle position
    }

    setBallPositions(
      balls.map((number, index) => ({
        number,
        left: `${index * (100 / balls.length)}%`,
      }))
    );
  };


  const Ball = ({ number, isWinner }) => {
    return (
      <Box
        className={`${classes.ball} ${isSpinning ? classes.spinning : ''}${isWinner ? classes.winnerBall : ''} `}
        sx={{ backgroundColor: colors[number - 1]?.light }}
      // style={{ left }}
      >
        {number}
      </Box>
    );
  };

  const BallRow = () => {
    const dynamicWidth = `${ballWidth * 45 + 10}px`;
    return (

      <Box className={classes.ballRow} style={{ width: dynamicWidth }} >
        {ballPositions.map((pos, index) => (
          <Ball
            key={index}
            number={pos.number}

            // left={pos.left}
            isWinner={isWinnerBallHighlighted && randomBall === pos.number}
          />
        ))}
      </Box>
    );
  };


  const classes = useStyles({});

  const [valueData, setValueData] = useState(false);
  const [prizeNumber1, setPrizeNumber1] = useState(0);

  const [prizeNumber2, setPrizeNumber2] = useState(0);
  const [prizeNumber3, setPrizeNumber3] = useState(0);
  const [prizeNumber4, setPrizeNumber4] = useState(0);
  const [prizeNumber5, setPrizeNumber5] = useState(0);
  const [prizeNumber6, setPrizeNumber6] = useState(0);
  const [showModal, setShowModal] = useState(true);

  const [formData, setFormData] = useState({
    lotteryId: "",
    ticketNumber: "",
    lotteryRank: 0,
    lotteryWinningPrize: "",
  });
  const [wheelRotations, setWheelRotations] = useState(WinnerArray.length);
  const [count, setCount] = useState(0);
  const [countValue, setCountValue] = useState(1);
  const [countWin, setCountWin] = useState(2);
  const [hasCompletedRotation, setHasCompletedRotation] = useState(false);
  const [winningList, setWinningList] = useState<winningList[] | null>(null);

  const [remainingTime, setRemainingTime] = useState(); // 5 minutes in seconds
  const [startSpinning, setMustSpin] = useState(false);
  const [startSpinning6, setMustSpin6] = useState(false);
  const [startSpinning5, setMustSpin5] = useState(false);

  const [startSpinning4, setMustSpin4] = useState(false);
  const [startSpinning3, setMustSpin3] = useState(false);
  const [startSpinning2, setMustSpin2] = useState(false);
  const [startSpinning1, setMustSpin1] = useState(false);
  // const [startDraw, setStartDraw] = useState(false);


  const [tiketcolors, setticketColors] = useState<Color[]>([]);
  const [triggerFetchTicket, setTriggerFetchTicket] = useState(false);
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [showJackpotCount, setShowJackpotCount] = useState(0)
  const [popupCount, setPopupCount] = useState(0);
  const [isDataReady, setIsDataReady] = useState(false);


  useEffect(() => {

    // getData();
    getTicketPurchaseSummary();
  }, [triggerFetch]);




  useEffect(() => {
    if (LotteryData?.lotteryDrawDate) {
      const lotteryDrawTime = new Date(LotteryData?.lotteryDrawDate).getTime();
      const currentTime = new Date().getTime();
      const timeRemaining = lotteryDrawTime - currentTime;

      if (timeRemaining > 0) {
        const timer = setTimeout(() => {
          setTriggerFetch(prev => !prev);  // Toggle the state to trigger re-fetch
        }, timeRemaining);

        // Clear timeout if the component unmounts or lotteryDrawDate changes
        return () => clearTimeout(timer);
      } else {
        // If the draw date has already passed, fetch immediately
        setTriggerFetch(prev => !prev);  // Toggle the state to trigger re-fetch
      }

    }
  }, [LotteryData?.lotteryDrawDate]);


  useEffect(() => {
    let token = storage.getToken();
    const fetchLotteryData = async () => {
      await axios
        .get(
          `${process.env.API_URL_PREFIX}/api/v1/user/lotteryTiketNumberList/` + id,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((res) => {
          setDataLottery(res?.data)
        })

        .catch((err) => {
          console.log(err);
        });
    };

    fetchLotteryData();
  }, [id, triggerFetch]);
  useEffect(() => {
    if (!userData || !id) return; // Ensure userData and id are available

    let token = storage.getToken();

    const fetchLotteryData = async () => {
      await axios
        .get(
          `${process.env.API_URL_PREFIX}/api/v1/user/getJackpotColorSelectedByUser/${id}/${userData}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((res) => {
          setLotteryTiccketColour(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    fetchLotteryData();
  }, [userData, id, triggerFetchTicket]); // Depend on userData, id, and triggerFetch


  useEffect(() => {
    const getUserData = async () => {
      let token = storage.getToken();
      try {
        const response = await axios.get(
          `${process.env.API_URL_PREFIX}/api/v1/user/userdetail`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        setUserData(response?.data?.data?._id);
        setTriggerFetchTicket(true); // Trigger the next fetch after userData is set
      } catch (err) {
        console.log(err);
      }
    };
    getUserData();
  }, []);


  const ChoosedBall = ({ color, number }: { color: string; number: number }) => (
    <Box
      sx={{
        backgroundColor: color,
        color: 'white',
        width: 40,
        height: 40,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 5px',
        fontSize: '1rem',
        fontWeight: 'bold',
      }}
    >
      {number}
    </Box>
  );

  const getColorByNumber = (number: number) => {
    if (number >= 1 && number <= 7) {
      return colors[number - 1];
    }
    return null; // Default or error case
  };

  const ChoosedBallRow = ({ colors, numbers }: { colors: Color[], numbers: number[] }) => (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {colors && colors.map((color, index) => (
        <ChoosedBall key={index} color={color?.light.toLowerCase()} number={numbers[index]} />
      ))}
    </Box>
  );

  useEffect(() => {
    const fetchRemainingTime = async () => {
      let token = storage.getToken();
      await axios
        .get(
          `${process.env.API_URL_PREFIX}/api/v1/user/endDateDrawDateTime/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "*",
            }
          }
        )
        .then((res) => {
          setRemainingTime(res?.data?.drawDateDateTime);
          const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
          const timeDifference = res?.data?.drawDateInSec - currentTime; // Remaining time in seconds

          // if (timeDifference >= 0) {
          //   setLotteryPool(false);

          // Start an interval to check the time difference
          const interval = setInterval(() => {
            const newCurrentTime = Math.floor(Date.now() / 1000);
            const newTimeDifference = res?.data?.drawDateInSec - newCurrentTime;

            if (newTimeDifference <= 0) {
              clearInterval(interval);
              setShowModal(false);
              setLotteryPool(true);
              getRPMData();
            }
          }, 1000); // Check every second
          // } else {
          //   setShowModal(false);
          //   setLotteryPool(true);
          //   getRPMData();
          // }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    fetchRemainingTime();
  }, [id]);



  const setPrizeNumbers = async (values: any[]) => {
    return new Promise((_resolve) => {
      values.forEach((value: any, index: number) => {
        const setter = eval(`setPrizeNumber${index + 1}`);
        setter(value);
      });
    });
  };

  const setMustSpins = async (values: any[]) => {
    return new Promise((_resolve) => {
      values.forEach((_value: any, index: number) => {
        const setter = eval(`setMustSpin${index + 1}`);
        setter(true);
      });
    });
  };

  const setMustSpinsStop = (values: any[]) => {
    values.forEach((_value: any, index: number) => {
      const setter = eval(`setMustSpin${index + 1}`);
      setter(false);
    });
  };
  useEffect(() => {


    if (startDraw || jackpotStart) {


      if (
        hasCompletedRotation &&
        isVisiblePrize1 &&
        isVisiblePrize2 &&
        isVisiblePrize3 &&
        isVisiblePrize4 &&
        isVisiblePrize5 &&
        isVisiblePrize6 &&
        WinnerArray.length - 1 !== count

      ) {

        setTimeout(() => {
          setCount((prevIndex) => prevIndex + 1);
          setCountWin((prevIndex) => prevIndex + 1);
          setIsVisiblePrize1(false);
          setIsVisiblePrize2(false);
          setIsVisiblePrize3(false);
          setIsVisiblePrize4(false);
          setIsVisiblePrize5(false);
          setIsVisiblePrize6(false);
          setHasCompletedRotation(false);
          setCountValue((prevIndex) => prevIndex + 1);
        }, 10000);
      }
      const spinNextWheel = async () => {
        if (wheelRotations >= 0 && WinnerArray.length > 0) {
          //3 //2 //1
          await handleSpinClick(WinnerArray[count]); //0 , //1, //2 (6
          //3 //-1 //-2
        }
        //requestAnimationFrame(spinNextWheel);
      };

      if (!hasCompletedRotation) {
        spinNextWheel();
      }
    }

  }, [WinnerArray, hasCompletedRotation, startDraw, jackpotStart]);

  const handleSpinClick = async (value: any) => {

    setIsMuted(false);
    // toggleMute();
    //setPlaySound(t);
    if (!startSpinning) {

      setMustSpin1(true);
      setTimeout(() => {
        setMustSpin2(true);
      }, 1000);
      setTimeout(() => {
        setMustSpin3(true);
      }, 2000);
      setTimeout(() => {
        setMustSpin4(true);
      }, 3000);
      setTimeout(() => {
        setMustSpin5(true);
      }, 4000);
      setTimeout(() => {
        setMustSpin6(true);

      }, 5000);
      let valuedata: number[];
      valuedata = Array.from(value?.toString()).map(Number);
      setMustSpins([0, 1, 2, 3, 4, 5]);
      setPrizeNumbers(valuedata);
      // setTimeout(() => {
      // setShowJackpotCount(showJackpotCount+1)

      // }, 6000);


    }
  };





  useEffect(() => {
    let token = storage.getToken();

    if (

      WinnerArray.length > 0 &&
      isVisiblePrize1 &&
      isVisiblePrize2 &&
      isVisiblePrize3 &&
      isVisiblePrize4 &&
      isVisiblePrize5 &&
      isVisiblePrize6
    ) {
      setHasCompletedRotation(true);
      setPlaySound(false);
      setFormData({
        lotteryId: id?.toString() || "",
        ticketNumber: dataLottery?.data[count]
          ? dataLottery?.data[count]
          : "",
        lotteryRank: count,
        lotteryWinningPrize: "1000",
      });
      if (wheelRotations !== 0) setWheelRotations((prevIndex) => prevIndex - 1);
      else if (wheelRotations === 0) setWheelRotations(0);


      if (conditionalPopup == true) {
        postLotteryDraw({
          lotteryId: id?.toString() || "",
          ticketNumber: WinnerArray[count] ? WinnerArray[count] : "",
          lotteryRank: count,
          lotteryWinningPrize: lotterySpin[count],
        });
        getWinner();

        getWinnerStatus(WinnerArray[count]);
      }

      setMustSpinsStop([0, 1, 2, 3, 4, 5]);
      if (WinnerArray.length === count + 1) {

        setMessage(
          "Sorry, you did not win. Try your luck in the next Lottery."
        );
      }
      if (WinnerArray.length === count + 1) {
        axios
          .put(
            `${process.env.API_URL_PREFIX}/api/v1/user/cleanWinnerArray/` +
            id,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
              },
            }
          )
          .then((res) => {
            const timer = setTimeout(() => { }, 3000);


            return () => clearTimeout(timer);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, [
    isVisiblePrize1,
    isVisiblePrize2,
    isVisiblePrize3,
    isVisiblePrize3,
    isVisiblePrize4,
    isVisiblePrize5,
    isVisiblePrize6,

  ]);
  const numnerOfSpinner = LotteryData?.totalDraw

  const getWinnerStatus = async (ticketNumber) => {
    let token = storage.getToken();
    let formData = { ticketNumber: ticketNumber };

    await axios
      .post(`${process.env.API_URL_PREFIX}/api/v1/user/userWinLooseStatus/` + id, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {

        let lotteryStatus = res?.data?.userDashboardCount?.[0]?.lotteryStatus;
        if (lotteryStatus == "Pending") {
          getWinnerStatus(WinnerArray[count]);
        }
        if (res.data.userDashboardCount.length === 0 || lotteryStatus === "Loose") {

          setIsLostopen(true);
          demoCount = demoCount - 1;

          setPopupCount((prevCount) => {
            const newCount = prevCount + 1;
            if (newCount === numnerOfSpinner && jackpotSelected == true) {
              getJackpotWinningData()
              setTimeout(() => {
                setJackpotShowModal(true);
              }, 6000);
            }
            return newCount;
          });

          setTimeout(() => {
            setIsLostopen(false);
          }, 8000);


        } else if (lotteryStatus === "Winner" || lotteryStatus === "Winner+Free Ticket") {
          setIsWinopen(true);
          setShowJackpotCount(showJackpotCount + 1);
          demoCount = demoCount - 1;

          setPopupCount((prevCount) => {
            const newCount = prevCount + 1;
            if (newCount === numnerOfSpinner && jackpotSelected == true) {
              getJackpotWinningData()
              setTimeout(() => {
                setJackpotShowModal(true);
              }, 8000);
            }
            return newCount;
          });

          setTimeout(() => {
            setIsWinopen(false);
          }, 8000);

        } else if (lotteryStatus === "Free Ticket") {
          setIsPrizeOpen(true);

          setPopupCount((prevCount) => {
            const newCount = prevCount + 1;
            if (newCount === numnerOfSpinner && jackpotSelected == true) {
              getJackpotWinningData()
              setTimeout(() => {
                setJackpotShowModal(true);
              }, 8000);
            }
            return newCount;
          });

          setTimeout(() => {
            setIsPrizeOpen(false);
          }, 8000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  // const getWinnerStatus = async (ticketNumber) => {
  //   try {
  //     let token = storage.getToken();
  //     let formData = { ticketNumber };
  //     console.log("formData", formData)
  //     const res = await axios.post(`${process.env.API_URL_PREFIX}/api/v1/user/userWinLooseStatus/${id}`, formData, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "Access-Control-Allow-Origin": "*",
  //       },
  //     });

  //     const userDashboardCount = res?.data?.userDashboardCount;
  //     const lotteryStatus = userDashboardCount?.[0]?.lotteryStatus;

  //     if (!userDashboardCount?.length || lotteryStatus === "Loose") {
  //       setIsLostopen(true);
  //       demoCount -= 1;

  //       setPopupCount((prevCount) => {
  //         const newCount = prevCount + 1;
  //         if (newCount === numnerOfSpinner) {
  //           setTimeout(() => setJackpotShowModal(true), 6000);
  //         }
  //         return newCount;
  //       });

  //       setTimeout(() => setIsLostopen(false), 8000);

  //     } else if (lotteryStatus === "Winner" || lotteryStatus === "Winner+Free Ticket") {
  //       console.log("calling abhay abhay")
  //       setIsWinopen(true);
  //       setShowJackpotCount((prevCount) => prevCount + 1);
  //       demoCount -= 1;

  //       setPopupCount((prevCount) => {
  //         const newCount = prevCount + 1;
  //         if (newCount === numnerOfSpinner) {
  //           setTimeout(() => setJackpotShowModal(true), 8000);
  //         }
  //         return newCount;
  //       });

  //       setTimeout(() => setIsWinopen(false), 8000);

  //     } else if (lotteryStatus === "Free Ticket") {
  //       setIsPrizeOpen(true);

  //       setPopupCount((prevCount) => {
  //         const newCount = prevCount + 1;
  //         if (newCount === numnerOfSpinner) {
  //           setTimeout(() => setJackpotShowModal(true), 8000);
  //         }
  //         return newCount;
  //       });

  //       setTimeout(() => setIsPrizeOpen(false), 8000);
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };




  useEffect(() => {
    const getSelectedBall = async () => {
      let token = storage.getToken();
      try {
        const res = await axios.get(
          `${process.env.API_URL_PREFIX}/api/v1/user/fetchJackpotColorSelectedByUser/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        const colorNumbers = res?.data?.data;
        const selectedColors = colorNumbers.map((number: number) => getColorByNumber(number)).filter((color: Color | null): color is Color => color !== null);
        setSelectedBall(selectedColors);
        setNumbers(colorNumbers);
        setticketColors(selectedColors)
      } catch (err) {
        console.log(err);
      }
    };

    getSelectedBall();
  }, [id]);


  const getWinner = async () => {
    let token = storage.getToken();
    await axios
      .get(
        `${process.env.API_URL_PREFIX}/api/v1/user/lotteryWinnerList/` +
        id,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        setWinningList(res?.data.data);
        setIsSpinDone(true);
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const getChildComp1 = useMemo(
    () => (
      <Wheel
        continueSpin={(avgSpeed / 60) * avgSpeed * 10 * 6}
        mustStartSpinning={startSpinning6}
        prizeNumber={prizeNumber1}
        data={data1}
        innerRadius={82}
        heightPercentage={74}
        widthPercentage={74}
        width={400}
        height={400}
        stopSpinTime={15000}
        fontSize={fontSize1}
        textDistance={textDistance1}
        perpendicularText={true}
        startingOptionIndex={0}
        backgroundColors={backgroundColors}
        onStopSpinning={() => {
          // setMustSpin6(false);
          setIsVisiblePrize1(true);
          audio.play();
        }}
      />
    ),
    [startSpinning6]
  );


  const getChildComp2 = useMemo(
    () => (
      <Wheel
        continueSpin={(avgSpeed / 60) * avgSpeed * 10 * 5}
        mustStartSpinning={startSpinning5}
        prizeNumber={prizeNumber2}
        data={data2}
        innerRadius={79}
        heightPercentage={65}
        widthPercentage={65}
        fontSize={fontSize2}
        width={350}
        height={350}
        stopSpinTime={12000}
        textDistance={textDistance2}
        perpendicularText={true}
        startingOptionIndex={0}
        backgroundColors={backgroundColors}
        onStopSpinning={() => {
          // setMustSpin5(false);
          setIsVisiblePrize2(true);
          audio.play();
        }}
      />
    ),
    [startSpinning5]
  );
  const getChildComp3 = useMemo(
    () => (
      <Wheel
        continueSpin={(avgSpeed / 60) * avgSpeed * 10 * 4}
        mustStartSpinning={startSpinning4}
        prizeNumber={prizeNumber3}
        data={data3}
        innerRadius={76}
        heightPercentage={56}
        fontSize={fontSize3}
        widthPercentage={56}
        width={300}
        height={300}
        stopSpinTime={9000}
        textDistance={textDistance3}
        perpendicularText={true}
        startingOptionIndex={0}
        backgroundColors={backgroundColors}
        onStopSpinning={() => {
          // setMustSpin4(false);
          setIsVisiblePrize3(true);
          audio.play();
        }}
      />
    ),
    [startSpinning4]
  );
  const getChildComp4 = useMemo(
    () => (
      <Wheel
        continueSpin={(avgSpeed / 60) * avgSpeed * 10 * 3}
        mustStartSpinning={startSpinning3}
        prizeNumber={prizeNumber4}
        data={data4}
        innerRadius={73}
        fontSize={fontSize4}
        heightPercentage={48}
        widthPercentage={48}
        width={250}
        height={250}
        stopSpinTime={6000}
        textDistance={textDistance4}
        perpendicularText={true}
        startingOptionIndex={0}
        backgroundColors={backgroundColors}
        onStopSpinning={() => {
          // setMustSpin3(false);
          setIsVisiblePrize4(true);
          audio.play();
        }}
      />
    ),
    [startSpinning3]
  );
  const getChildComp5 = useMemo(
    () => (
      <Wheel
        continueSpin={(avgSpeed / 60) * avgSpeed * 10 * 2}
        mustStartSpinning={startSpinning2}
        prizeNumber={prizeNumber5}
        data={data5}
        innerRadius={70}
        fontSize={fontSize5}
        heightPercentage={39}
        widthPercentage={39}
        width={200}
        height={200}
        stopSpinTime={3000}
        textDistance={textDistance5}
        perpendicularText={true}
        startingOptionIndex={0}
        backgroundColors={backgroundColors}
        onStopSpinning={() => {
          // setMustSpin2(false);
          setIsVisiblePrize5(true);
          audio.play();
        }}
      />
    ),
    [startSpinning2]
  );
  const getChildComp6 = useMemo(
    () => (
      <Wheel
        continueSpin={(avgSpeed / 60) * avgSpeed * 10}
        mustStartSpinning={startSpinning1}
        prizeNumber={prizeNumber6}
        data={data6}
        innerRadius={70}
        fontSize={15}
        heightPercentage={29}
        widthPercentage={29}
        width={180}
        height={180}
        stopSpinTime={0}
        textDistance={80}
        perpendicularText={true}
        startingOptionIndex={0}
        backgroundColors={backgroundColors}
        onStopSpinning={() => {
          // setMustSpin1(false);
          setIsVisiblePrize6(true);
          audio.play();
        }}
      />
    ),
    [startSpinning1]
  );

  const handleStartDraw = () => {
    // setStartDraw(true);
    if (WinnerArray && WinnerArray.length == 0) {
      getData()
    }
    startDraw = true;

    setLotteryPool(false);


  }

  const getJackpotWinningData = async () => {
    let token = storage.getToken();

    try {
      const res = await axios.get(
        `${process.env.API_URL_PREFIX}/api/v1/user/playJackpot/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      setJackpotWinningData(res.data);
      setIsDataReady(true)
      setRandomBall(res?.data?.data?.randomBall);



    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        console.log("Error fetching lottery data:", err.response ? err.response.data : err.message);
      } else {
        console.log("Unexpected error:", err);
      }
    }
  };

  // useEffect(() => {

  //   const getJackpotTicketData = async () => {
  //     let token = storage.getToken();

  //     try {
  //       const res = await axios.get(
  //         `${process.env.API_URL_PREFIX}/api/v1/user/playJackpot/${id}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //             "Access-Control-Allow-Origin": "*",
  //           },
  //         }
  //       );

  //       setJackpotWinningTicketData(res.data);



  //     } catch (err: unknown) {
  //       if (axios.isAxiosError(err)) {
  //         console.log("Error fetching lottery data:", err.response ? err.response.data : err.message);
  //       } else {
  //         console.log("Unexpected error:", err);
  //       }
  //     }
  //   };
  //   setTimeout(() => {
  //     getJackpotTicketData()
  //   }, 5000)
  // }, [])

  // const handleStartJackpotDraw = () => {
  // handleJackpotModalClose();
  // getJackpotWinningData();
  // setIsSpinning(true);
  // setCount(0)
  // jackpotStart = true
  // setHasCompletedRotation(false);
  // conditionalPopup = false
  // setIsVisiblePrize1(false)
  // setIsVisiblePrize2(false)
  // setIsVisiblePrize3(false)
  // setIsVisiblePrize4(false)
  // setIsVisiblePrize5(false)
  // setIsVisiblePrize6(false)
  // console.log(jackpotWinnerArray, "jackpotWinnerArray before updating winnerArray");

  // if(jackpotWinnerArray != null){

  //   setWinnerArray([jackpotWinnerArray])
  // }
  // console.log([jackpotWinningData], "[jackpotWinningData?.data?.getJackpotWinTicket]");


  // setTimeout(() => {
  //   setIsSpinning(false);
  //   if (spinningBallData && typeof spinningBallData.randomGeneratedBall === 'number') {
  //     console.log("Kuber Bhai")

  //     setTimeout(() => {
  //       setIsJackpotWinopen(true)
  //       setIsJackpotLostopen(true)
  //     }, 5000)

  //   }
  //   console.log(spinningBallData, "spinningBallData")
  // }, 20000);

  // }


  const handleStartJackpotDraw = async () => {
    if (isDataReady == true) {
      handleJackpotModalClose();
      setIsSpinning(true);

      setCount(0)
      jackpotStart = true
      setHasCompletedRotation(false);
      conditionalPopup = false
      setIsVisiblePrize1(false)
      setIsVisiblePrize2(false)
      setIsVisiblePrize3(false)
      setIsVisiblePrize4(false)
      setIsVisiblePrize5(false)
      setIsVisiblePrize6(false)

      if (jackpotWinnerArray != null) {

        setWinnerArray([jackpotWinnerArray])
      }


      setTimeout(() => {
        setIsSpinning(false);
        setSpinningArray(true)
        setIsWinnerBallHighlighted(true);
        setTimeout(() => {
          setIsJackpotWinopen(true)
          setIsJackpotLostopen(true)
        }, 3000)


      }, 20000);
    }
  }




  // useEffect(() => {
  //   if (!randomBall) {
  //     if (spinningBallData && typeof spinningBallData.randomGeneratedBall === 'number') {
  //     setRandomBall(spinningBallData?.randomGeneratedBall); // Ensure this function sets the randomBall
  //     }
  //   }
  // }, []);

  const getTicketPurchaseSummary = async () => {
    let token = storage.getToken();
    await axios
      .get(
        `${process.env.API_URL_PREFIX}/api/v1/user/lotteryTicketPurchaseSummary/` +
        id,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        setNoOfTicketsSold(res?.data?.numOfTicketSold);
        setPercentageOfTicketSold(res?.data?.percentageTktSale);
        setTotalPrizeOfTicket(res?.data?.sumTicketPrice !== null ? res?.data?.sumTicketPrice : 0)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <div style={{ paddingTop: "85px" }}></div>
      <Box
        className="wheel-container"
        id="container"
        sx={{
          backgroundColor: isWinOpen ? "transparent" : "rgba(5, 5, 24, 0.8)",
          borderRadius: "10px",
          p: { md: "15px", xs: "5px" },
          px: { md: "23px", xs: "0px" },
          m: { md: "15px", xs: "2px" },
          minHeight: { xs: "290px", sm: "290px", md: "500px" },
          height: "auto",
          pb: "40px !important",
          marginLeft: "20px"
        }}
      >
        <Box sx={{ borderRadius: "10px", py: "62px", p: "0" }}>
          <Grid container>
            <Grid item xs={3} md={3} sx={{ maxHeight: "500px", mt: "20px" }}>
              <Box className="lotterynumber-div">

                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: "15px"
                }}>
                  <Box
                    id="outer"
                    className={classes.boxShadow}
                    sx={{
                      width: { md: "312px", xs: "145px" },
                      height: { md: "47px", xs: "20px" },
                      borderRadius: "12px",
                      display: "flex",
                      mb: "20px",
                      border: "4px solid #3b97bb",
                    }}
                  >
                    <Box
                      className={classes.boxShadow}
                      sx={{
                        width: { md: "52px", xs: "20px" },
                        height: { md: "47px", xs: "20px" },
                        background: isVisiblePrize1 ? "#750787" : "transparent",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      {isVisiblePrize1 ? (
                        <CountUp start={0} end={prizeNumber1} delay={0}>
                          {({ countUpRef }) => (
                            <Box
                              sx={{
                                color: "#FFFF",
                                fontSize: { xs: "10px", md: "40px" },
                                fontWeight: "bold",
                              }}
                            >
                              <span ref={countUpRef} />
                            </Box>
                          )}
                        </CountUp>
                      ) : (
                        ""
                      )}
                    </Box>

                    <Box
                      className={classes.boxShadow}
                      sx={{
                        width: { md: "52px", xs: "20px" },
                        height: { md: "47px", xs: "20px" },
                        background: isVisiblePrize2 ? "#1C6CF2" : "transparent",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      {isVisiblePrize2 ? (
                        <CountUp start={0} end={prizeNumber2} delay={0}>
                          {({ countUpRef }) => (
                            <Box
                              sx={{
                                color: "#FFFF",
                                fontSize: { xs: "10px", md: "40px" },
                                fontWeight: "bold",
                              }}
                            >
                              <span ref={countUpRef} />
                            </Box>
                          )}
                        </CountUp>
                      ) : (
                        ""
                      )}
                    </Box>

                    <Box
                      className={classes.boxShadow}
                      sx={{
                        width: { md: "52px", xs: "20px" },
                        height: { md: "47px", xs: "20px" },
                        background: isVisiblePrize3 ? "#008026" : "transparent",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      {isVisiblePrize3 ? (
                        <CountUp start={0} end={prizeNumber3} delay={0}>
                          {({ countUpRef }) => (
                            <Box
                              sx={{
                                color: "#FFFF",
                                fontSize: { xs: "10px", md: "40px" },
                                fontWeight: "bold",
                              }}
                            >
                              <span ref={countUpRef} />
                            </Box>
                          )}
                        </CountUp>
                      ) : (
                        ""
                      )}
                    </Box>

                    <Box
                      className={classes.boxShadow}
                      sx={{
                        width: { md: "52px", xs: "20px" },
                        height: { md: "47px", xs: "20px" },
                        background: isVisiblePrize4 ? "#E40303" : "transparent",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      {isVisiblePrize4 ? (
                        <CountUp start={0} end={prizeNumber4} delay={0}>
                          {({ countUpRef }) => (
                            <Box
                              sx={{
                                color: "#FFFF",
                                fontSize: { xs: "10px", md: "40px" },
                                fontWeight: "bold",
                              }}
                            >
                              <span ref={countUpRef} />
                            </Box>
                          )}
                        </CountUp>
                      ) : (
                        ""
                      )}
                    </Box>

                    <Box
                      className={classes.boxShadow}
                      sx={{
                        width: { md: "52px", xs: "20px" },
                        height: { md: "47px", xs: "20px" },
                        background: isVisiblePrize5 ? "#FF8C00" : "transparent",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      {isVisiblePrize5 ? (
                        <CountUp start={0} end={prizeNumber5} delay={0}>
                          {({ countUpRef }) => (
                            <Box
                              sx={{
                                color: "#FFFF",
                                fontSize: { xs: "10px", md: "40px" },
                                fontWeight: "bold",
                              }}
                            >
                              <span ref={countUpRef} />
                            </Box>
                          )}
                        </CountUp>
                      ) : (
                        ""
                      )}
                    </Box>
                    <Box
                      className={classes.boxShadow}
                      sx={{
                        width: { md: "52px", xs: "20px" },
                        height: { md: "47px", xs: "20px" },
                        background: isVisiblePrize6 ? "#e55934" : "transparent",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      {isVisiblePrize6 ? (
                        <CountUp start={0} end={prizeNumber6} delay={0}>
                          {({ countUpRef }) => (
                            <Box
                              sx={{
                                color: "#FFFF",
                                fontSize: { xs: "10px", md: "40px" },
                                fontWeight: "bold",
                              }}
                            >
                              <span ref={countUpRef} />
                            </Box>
                          )}
                        </CountUp>
                      ) : (
                        ""
                      )}
                    </Box>
                  </Box>
                  <Box sx={{ marginBottom: "15px" }}>
                    <ChoosedBallRow colors={selectedBall} numbers={numbers} />
                  </Box>

                </Box>


                <div className="mob-screen-audio">
                  <audio ref={audioElement} src={sound} />
                  <IconButton onClick={toggleMute} sx={{ padding: { xs: "0", md: "10px" } }}>
                    {isMuted ? (
                      <VolumeOffIcon sx={{ color: "white" }} />
                    ) : (
                      <VolumeUpIcon sx={{ color: "white" }} />
                    )}
                  </IconButton>
                </div>



                <Box
                  id="lottery-tickets-list-wheellpage"
                  className={classes.list}
                  sx={{ mt: "20px", maxHeight: "320px" }}
                >
                  <Grid container>
                    {dataLottery?.data.map((row, index) => {
                      let bgColor = "#ffffff";
                      let currentIndex = 0;

                      if (lotteryTicketColour?.data) {
                        for (let item of lotteryTicketColour.data) {
                          if (index < currentIndex + item.count) {
                            const colorIndex = parseInt(item.selectedJackpotColourNumber) - 1;
                            bgColor = colors[colorIndex]?.light || "#ffffff";
                            break;
                          }
                          currentIndex += item.count;
                        }
                      }

                      return (
                        <Grid item md={5} key={index}>
                          <Paper
                            elevation={3}
                            className={classes.ticketContainer}
                            sx={{
                              marginTop: '20px',
                              backgroundColor: bgColor,
                              color: "black",
                            }}
                          >
                            <div className={classes.detailContainer}>
                              <div>
                                <Typography variant="body2" className={classes.detailLabel}>
                                  Ticket no.
                                </Typography>
                                <Typography variant="h6" className={classes.detailNumber}>
                                  {row}
                                </Typography>
                              </div>
                              <Divider
                                sx={{
                                  borderRight: "0.46px black dotted",
                                  position: "absolute",
                                  left: "85%",
                                  top: "2%",
                                }}
                                orientation="vertical"
                              />
                            </div>

                            <div style={{ flex: 1, borderRadius: "0 10px 10px 0" }}>
                              <img
                                src={arrow}
                                alt="Up Arrow"
                                className={`${classes.arrowImage} ${classes.polyup}`}
                              />
                              <img
                                src={arrow}
                                alt="Down Arrow"
                                className={`${classes.arrowImage} ${classes.polydown}`}
                              />
                            </div>
                          </Paper>
                        </Grid>
                      );
                    })}
                  </Grid>



                </Box>


              </Box>
            </Grid>

            <Grid
              item
              xs={4}
              md={6}
              sx={{
                display: "center",
                justifyContent: "center",
                alignItems: "center",
                mt: "20px"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  position: "relative",
                  bottom: { xs: "83%", md: "80%" },
                  transform: { xs: "scale(0.57)", md: "scale(1)" },
                }}
                className={classes.leftPosition}
              >
                <div
                  className="App"
                  style={{ fontFamily: "Nunito", position: "absolute", marginRight: "100px" , textAlign: "left" }}
                >
                  <div className="box-spin box-spin1">
                    <div className="font-spin">
                      {showModal ? (
                        <span
                          style={{
                            position: "relative",
                            top: "0px",
                            paddingLeft: "40px",
                            paddingRight: "40px",
                          }}
                        >
                          <Countdown date={new Date(`${remainingTime}`)} />
                        </span>
                      ) : (
                        <>
                          <span style={{ position: "relative", top: "-5px" }}>
                            Spin {countValue}
                          </span>
                          <span
                            className="wheel-arrow"
                            style={{ position: "relative" }}
                          >
                            <ArrowRightAltIcon />
                          </span>
                          <span style={{ position: "relative", top: "-5px" }}>
                            <img
                              src={whitet}
                              height="14"
                              width="14"
                              style={{ marginRight: "4px" }}
                            />
                            {lotterySpin[count]}
                          </span>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="speed">
                    <img src={speed} alt="speed" className="rpm2" />
                    <p style={{ color: "#fffff" }} className="speed3">
                      Break <br /> {decelaration.toFixed(2)}
                    </p>
                  </div>
                  <header className="App-header">
                    <div className="box-spin box-spin2">
                      <div className="font-spin">
                        {showModal ? (
                          <span
                            style={{
                              position: "relative",
                              top: "0px",
                              paddingLeft: "40px",
                              paddingRight: "40px",
                            }}
                          >
                            <Countdown date={new Date(`${remainingTime}`)} />
                          </span>
                        ) : (
                          <>
                            <span style={{ position: "relative", top: "-5px" }}>
                              Spin {countValue}
                            </span>
                            <span
                              className="wheel-arrow"
                              style={{ position: "relative" }}
                            >
                              <ArrowRightAltIcon />
                            </span>
                            <span style={{ position: "relative", top: "-5px" }}>
                              <img
                                src={whitet}
                                height="14"
                                width="14"
                                style={{ marginRight: "4px" }}
                              />
                              {lotterySpin[count]}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="parent parent-div">
                      {/* <div><p style={{textAlign:"center"}}>Spinning is winning</p></div> */}
                      <div className="circle0 circle-zero">
                        <div className="outerImg"></div>
                      </div>
                      <div className="circle1 circle-first">
                        {getChildComp1}
                      </div>
                      <div className="circle2 circle-second" id="wheel-2">
                        {getChildComp2}
                      </div>
                      <div className="circle3 circle-third">
                        {getChildComp3}
                      </div>
                      <div className="circle4 circle-four">{getChildComp4}</div>
                      <div className="circle5 circle-five">{getChildComp5}</div>
                      <div className="circle6 circle-six">
                        {getChildComp6}
                        {/* <button
                        className={"spin-button"}
                        onClick={handleSpinClick}
                      >
                        SPIN
                      </button> */}
                      </div>

                      <div className="circle7 circle-seven">
                        <div
                          style={{
                            fontWeight: "bold",
                            textAlign: "center",
                            display: "block",
                          }}
                        ></div>
                      </div>
                      <div className="circle8 circle-eight">
                        <div
                          style={{
                            fontWeight: "bold",
                            textAlign: "center",
                            display: "block",
                          }}
                        >
                          <span className="spiner-circle">
                            {avgSpeed.toFixed(2)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </header>
                  <div className="rpm1">
                    <img src={rpm} alt="rpm" className="rpm2" />
                    <p style={{ color: "#fffff" }} className="rpm3">
                      RPM<br /> {avgSpeed.toFixed(2)}
                    </p>
                  </div>


                  {/* <Box className="animation">
                    <video autoPlay loop muted playsInline className="background-video">
                      <source src="/mervsmalltransparent.webm" type="video/webm" />


                      Your browser does not support the video tag.
                    </video>
                  </Box> */}

                  <Box className="animation">
                    <img
                      src="/Comp_1.gif"
                      alt="Background Animation"
                      className="background-video"



                    />

                  </Box>

                </div>
              </Box>



              {/* Abhay */}


            </Grid>


            <Grid
              className="table-oriantation-fix"
              item
              xs={4}
              md={3}
              sx={{
                display: { xs: "block", md: "block" }, mt: {
                  sm: "0px !important",
                  md: "0px !important",
                  lg: "20px !important",
                },
              }}
            >


              {/* <Box className={classes.progressBollBoxOuter}>
                <SnookerBallAnimation />
              </Box> */}


              <Box>
                <BallRow />
              </Box>


              <TableContainer
                component={Paper}
                className={classes.tableContainer}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  ml: { xs: "56px", md: "0px" },

                }}
              >
                <Table
                  className={classes.table}
                  aria-label="custom table"
                  sx={{
                    p: { xs: "0px", sm: "10px", md: "25px" },
                    minWidth: "auto !important",
                    display: "block",
                    marginTop: "10px",
                    marginRight: "30px"
                  }}
                >
                  <TableHead sx={{ backgroundColor: "#0D0D36 !important" }}>
                    <TableRow>
                      <TableCell
                        className={classes.tableHeader}
                        sx={{ width: "100%" }}
                      >
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          sx={{
                            color: "#FFFFFF",
                            p: {
                              sm: "0px !important",
                              md: "0px !important",
                              lg: "0px !important",
                              xs: "0 !important",
                            },
                          }}
                        >
                          {lotteryNameData}
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={classes.tableHeader}
                        sx={{ p: { xs: "0px", md: "16px" } }}
                      ></TableCell>
                      <TableCell
                        className={classes.tableHeader}
                        sx={{ p: { xs: "0px", md: "16px" } }}
                      >
                        <img
                          src={
                            lotteryImage !== ""
                              ? `${process.env.API_URL_PREFIX}/api/v1/admin/uploads/${lotteryImage}`
                              : lotteryName
                          }
                          alt="lotteryName"
                          height="60px"
                          width="60px"
                          style={{
                            border: "2px solid #3b97bb",
                            borderRadius: "9px",
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody
                    className={classes.list}
                    sx={{
                      mt: "20px",
                      maxHeight: "320px",
                      display: "inline-block",
                      width: "100%",
                    }}
                  >
                    <TableRow
                      key={0}
                      sx={{
                        backgroundColor: "#3b97bb",
                        opacity: "1",
                        width: "100%",
                        display: "block",
                      }}
                    >
                      <TableCell
                        colSpan={2}
                        className={classes.tableData}
                        sx={{
                          opacity: "1 !important",
                          p: { xs: "0px", md: "16px" },
                          borderBottom: "none !important",
                          lineHeight: "1px",
                          pt: "0px !important",
                          pb: "0px !important"

                        }}
                      >
                        <p style={{ width: "max-content" }}>

                          Total Prize money
                        </p>
                      </TableCell>
                      {/* <TableCell className={classes.tableData}></TableCell> */}
                      <TableCell
                        className={classes.tableData}
                        sx={{
                          textAlign: "center",
                          opacity: "1 !important",
                          width: "46%",
                          p: { xs: "0px", md: "16px" },
                          borderBottom: "none !important",
                          lineHeight: "1px",
                          pt: "0px !important",
                          pb: "0px !important"

                        }}
                      >
                        <img src={whitet} height="13" width="13" />{" "}
                        {/* {lotterySpin.length > 0
                          ? lotterySpin.reduce(
                              (acc, curr) => acc + parseInt(curr),
                              0
                            )
                          : ""} */}
                        <CurrencyFormat value={lotterySpin.length > 0
                          ? lotterySpin.reduce(
                            (acc, curr) => acc + parseInt(curr),
                            0
                          )
                          : 0} thousandSeparator={','} displayType={'text'} decimalSeparator={'.'} />
                      </TableCell>

                    </TableRow>


                    {lotterySpin.map((row, index) => (
                      <TableRow
                        key={index + 1}
                        sx={{ width: "100%", display: "block", borderBottom: "1px solid #0D0D39" }}
                      >
                        <TableCell
                          // colSpan={2}
                          className={classes.tableData}
                          sx={{
                            p: { xs: "0px", md: "4px" },
                            paddingRight: { md: "52px", xs: "15px" },
                            width: "50%",
                            borderBottom: "none !important",
                            lineHeight: "1px",
                          }}
                        >
                          <ul style={{ margin: "0px" }}>
                            <li
                              className={
                                index === count ? "active" : "inactive"
                              }
                            >
                              <p className="m-0 mw-max" >

                                {index + 1 + "th spin"}
                              </p>
                            </li>
                          </ul>
                        </TableCell>
                        {/* <TableCell className={classes.tableData}></TableCell> */}
                        <TableCell
                          className={classes.tableData}
                          sx={{
                            textAlign: "center",
                            p: { xs: "0px", md: "4px" },
                            width: "50%",
                            borderBottom: "none !important",
                            lineHeight: "1px",

                          }}
                        >
                          <span
                            className={index === count ? "active" : "inactive"}
                          >
                            {index === count ? (
                              <img
                                src={yellowt}
                                height="12"
                                width="12"
                                style={{ marginRight: "4px" }}
                              />
                            ) : (
                              <img
                                src={whitet}
                                height="12"
                                width="12"
                                style={{ marginRight: "4px" }}
                              />
                            )}
                            {/* {lotterySpin[index]} */}
                            <CurrencyFormat value={lotterySpin[index]} thousandSeparator={','} displayType={'text'} decimalSeparator={'.'} />
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>

                </Table>


              </TableContainer>

            </Grid>


          </Grid>
        </Box>






      </Box>





      {WinnerArray.length === countValue && hasCompletedRotation ? (
        <Grid
          container
          display={"flex"}
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
          spacing={2}
          sx={{ marginBottom: "20px" }}
        >
          <Grid
            item
            xs={10}
            md={6}
            sx={{
              paddingLeft: "34px",
              marginLeft: "20px",
              marginBottom: "10px",
            }}
          >
            <Typography component="h4" variant="h4" sx={{ mb: "20px" }}>
              Winning Tickets
            </Typography>
            {WinnerArray.length === countValue ? (
              <WinningHistoryWheel />
            ) : (
              <Typography component="h4" variant="h4" sx={{ mb: "20px" }}>
                No winner
              </Typography>
            )}
          </Grid>
          <Grid
            item
            xs={4}
            md={5}
            xl={5}
            sx={{
              display: { md: "flex", xs: "none" },
              justifyContent: "flexStart",
              alignItems: "end",
              mt: "54px",
            }}
          >
            <img src={ad} alt="image" style={{ width: "100%" }} />
          </Grid>
        </Grid>
      ) : (
        ""
      )}
      {/* //backend --> lottery 10 ---> user(2) win */}
      {/* {winnerStatus? <WinModal open={isWinOpen} onClose={handleWinClose} />:
      <LostModal open={isLostOpen} onClose={handleLostClose} />} */}
      <WinModal
        open={isWinOpen}
        onClose={handleWinClose}
        winnerPrize={lotterySpin[count]}
        ticketNumber={WinnerArray[count]}
        countIndex={count}
        winnerWithTicket={winnerWithTicket}
      // Default value if message is undefined

      />
      <LostModal
        open={isLostOpen}
        onClose={handleLostClose}
        message={message}
      />
      <PrizeModal
        open={isPrizeOpen}
        onClose={handlePrizeClose}
      // message={message}
      />


      {jackpotWinningData && jackpotWinningData?.data?.jackpotStatus ?
        <JackpotWinModal
          open={isJackpotWinOpen}
          onClose={handleJackpotWinClose}
          JackpotPrize={jackpotWinningData.data.WinningAmount}
          jackpotRound={jackpotWinningData.data.jackpotRound}

        // Default value if message is undefined

        /> :
        <JackpotLostModal
          open={isJackpotLostOpen}
          onClose={handleJackpotLostClose}
          message={"Sorry, you did not win. Fingers crossed for the next Jackpot."}
        />

      }

      {/* jackpotMessage={jackpotWinningData?.message || ""}  jackpot message  */}

      <JackpotModal open={showjackpotModal} onClose={handleJackpotModalClose} handleStartJackpotDraw={handleStartJackpotDraw} />

      {lotteryPool ? (
        <LotteryPoolModal
          open={lotteryPool}
          onClose={handleLotteryPoolClose}
          noOfTicketsSold={noOfTicketsSold}
          percentageOfticketsSold={percentageOfTicketSold}
          totalPrizeofTickets={totalPrizeOfTicket}
          handleStartDraw={handleStartDraw}
        />
      ) : null}

      {isWinOpen ? (
        <Particles
          id="tsparticles"
          init={particlesInit}
          options={{
            preset: "fireworks",
            particles: {
              color: {
                value: ["#5bc0eb", "#fde74c", "#9bc53d", "#e55934", "#fa7921"],
              },
              background: {
                color: {
                  value: "transparent",
                },
                background: {
                  color: {
                    value: "#000000",
                  },
                },
                fpsLimit: 120,
                interactivity: {
                  events: {
                    onClick: {
                      enable: true,
                      mode: "push",
                    },
                    onHover: {
                      enable: false,
                      mode: "repulse",
                    },
                    resize: true,
                  },
                  modes: {
                    bubble: {
                      distance: 400,
                      duration: 2,
                      opacity: 0.8,
                      size: 40,
                    },
                    push: {
                      quantity: 4,
                    },
                    repulse: {
                      distance: 200,
                      duration: 0.4,
                    },
                  },
                },
                particles: {
                  color: {
                    value: "#ffffff",
                  },
                  links: {
                    color: "#ffffff",
                    distance: 150,
                    enable: false,
                    opacity: 0.4,
                    width: 1,
                  },
                  collisions: {
                    enable: true,
                  },
                  move: {
                    direction: "none",
                    enable: true,
                    outMode: "bounce",
                    random: true,
                    speed: 2,
                    straight: false,
                  },
                  fullScreen: {
                    enable: true,
                    zIndex: 0,
                  },
                  number: {
                    density: {
                      enable: true,
                      area: 500,
                    },
                    value: 80,
                  },
                  opacity: {
                    value: 0.5,
                  },
                  shape: {
                    type: "circle",
                  },
                  size: {
                    random: true,
                    value: 1,
                  },
                },
                detectRetina: true,
                fullScreen: {
                  enable: true,
                  zIndex: -1,
                },
              },
            },
          }}
        />
      ) : (
        ""
      )}
    </>
  );
};
