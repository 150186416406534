import { Close } from "@mui/icons-material";
import {
  Box,
  Stack,
  IconButton,
  Typography,
  Grid,
  Link,
  Button,
  CircularProgress,
} from "@mui/material";
import { CustomModel } from "@/components/Elements/custom-modal";
import openMail from "@/assets/closedMail.svg";
import OtpInput from "react-otp-input";
import { useState } from "react";
import { resendOTP, verifyOTPWithOTP } from "@/features/auth/api/verifyOTP";
import { useAuth } from "@/lib/auth";
import closeModal from "@/assets/closeModal.svg";
import { getTicketStatus } from "@/features/Tickets/api/getTicketStatus";
import storage from "@/utils/storage";
import { ticketListData } from "@/features/Tickets/types";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { getWalletAmount } from "../Elements/api/getWalletamount";
interface verifyOTPProps {
  open: boolean;
  onClose: () => void;
  email: string;
  //   onSuccess:() => void;
}

type verifyOTPValues = {
  otp: "";
  email: "";
};

const initialValues: verifyOTPValues = {
  otp: "",
  email: "",
};

const VerifyOTP = ({ open, onClose, email }: verifyOTPProps) => {
  let valueData: Promise<ticketListData[]>;
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const { login, isLoggingIn } = useAuth();
  const [isVerifying, setIsVerifying] = useState(false);
  console.log(isVerifying , "isklkslaklsk")
  const handleContinue = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setIsVerifying(true); // Start loader

    const data = { otp: otp, email: email };
    console.log(data, "data");

    try {
      const response = await login(data);
      if (storage.getToken()) {
        const dataWallet = await getWalletAmount();
        console.log("ddd", dataWallet?.data?.walletAmount);
        if (dataWallet?.data?.walletAmount === 0) {
          navigate("/app/wallet/MyLottery");
        } else {
          navigate("/app");
        }
      }
    } catch (err) {
      // Handle error
      console.error(err);
    } finally {
      setIsVerifying(false); // Stop loader
    }
  };


  const handleResend = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    const data = { email: email };
    open = true;
    await resendOTP(data);
    open = true;
  };

  return (
    <CustomModel
      open={open}
      onClose={onClose}
      childComponent={
        <>
          <Stack direction="row" height="100%" sx={{ p: 0 }}>
            <Box
              sx={{ width: "100%", overflow: "auto", my: { md: "50px", xs: "20px" }, ml: "50px" }}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <img
                src={openMail}
                alt="mailIcon"
                style={{ textAlign: "center", display: "block" }}
                width="60px"
                height="60px"
                className="image-otp"
              />

              <Typography
                component="h4"
                variant="h4"
                sx={{ mt: { md: "20px", xs: "7px" }, textAlign: "center" }}
              >
                Check your Email
              </Typography>
              <Typography
                component="h6"
                variant="h6"
                sx={{ textAlign: "center", mt: { xs: "5px", md: "15px" }, mb: "20px", ml: { xs: "15px", md: "0px" } }}
              >
                We sent a verification code to <br /> <span style={{ color: "#3C37FF" }}>{email}</span>
              </Typography>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={4}

                renderSeparator={<span style={{ marginRight: "10px" }}></span>}
                renderInput={(props) => <input {...props} />}
                inputStyle={"input-otp"}
              />
              <Grid container>
                {/* <Grid item xs={12}>
                  <Typography
                    component="h3"
                    variant="h3"
                    sx={{ textAlign: "center", mt: {xs:"25px", md:"40px" }}}
                  >
                    Didn't receive the email?
                   <Button>
                     <span  style={{ "textTransform":"capitalize" }} onClick={handleResend}>Resend</span> 
                    </Button>
                  </Typography>
                </Grid> */}
                <Grid
                  item
                  lg={12}
                  sx={{
                    mt: { xs: 1, md: 3 },
                    mb: { xs: "30px", md: "45px" },
                    ml: { xs: "137px", md: "0px" },
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={handleContinue}
                    sx={{ px: { md: "24px !important", xs: "10px !important" } }}
                    disabled={isVerifying} // Disable button while verifying
                  >
                    {isVerifying ? (
                      <CircularProgress size={24} color="inherit" /> // Show loader while verifying
                    ) : (
                      "Verify"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <IconButton sx={{ padding: { md: "25px", xs: "10px" } }} onClick={onClose}>
                <img src={closeModal} />
              </IconButton>
            </Box>
          </Stack>
        </>
      }
    />
  );
};

export default VerifyOTP;
