import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Paper,
    FormControl,
    InputLabel,
    Stack,
    Typography,
    Divider,
    CardMedia,
    Button,
    Icon,
    InputAdornment,
    TextField,
  } from "@mui/material";
  
  import { Grid, Select, MenuItem } from "@mui/material";
  import { useState } from "react";
  import { styled } from "@mui/material/styles";
  import { theme } from "@/components/Elements/theme";
  import cryptoPayments from "@/assets/cryptoPayments.svg";
  import allPayments from "@/assets/allPayments.svg";
import { WithDraw } from "./withdraw";
import { CryptoPaymentsModule } from "./cryptoPayment";
import { BankAccounts } from "./bankAccounts";
import React from "react";
import Swal from "sweetalert2";
import { getWalletAmount } from "@/components/Elements/api/getWalletamount";
  
  export const Payment = () => {
    const [cryptoPayment, setCryptoPayment] = useState(true);
    const handleCryptoPayment = () => {
      setCryptoPayment(!cryptoPayment);
    }

    React.useEffect(() => {
      async function checkBalance() {
            let dataWallet = await getWalletAmount()
            if(dataWallet?.data?.walletAmount===0){
              Swal.fire({
                text: "Deposit : Top up your wallet with crypto currency or fiat money and start playing ",
                icon:"info",
                background:"black",
                color:"white",
                showCancelButton: false,
                confirmButtonText: "OK",
                allowOutsideClick:false
              });
            }
          }
        checkBalance()
  }, []);

    const Item = styled(Paper)(({ theme }) => ({
      background: "transparent",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    }));
  
    var cardStyle = {
      display: "block",
      transitionDuration: "0.3s",
      height: "492px",
      background: "#050518",
      boxShadow: "0px 4px 50px 30px rgba(181, 181, 181, 0.05)",
      borderRadius: "20px",
    };
  
    var center = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    };
    var content = {
      color: "#ffff",
      backgroundColor: "#050518",
      borderRadius: "12px",
    };
  
    return (
      <Box>
        <Grid container sx={{ mt: theme.spacing(1) }}>
          <Grid item xs={6} md={6}>
            {" "}
            <Card
              style={cardStyle}
              sx={{ p: {md:theme.spacing(0.3), xs:theme.spacing(0.1)}, m: theme.spacing(0.2) , marginTop:theme.spacing(0.5) }}
            >
              <CardContent>
                <Stack spacing={2}>
                  <Item>
                    {" "}
                    <Typography variant="h5">Deposit</Typography>
                  </Item>
                  <Item sx={{mt:{xs:"0 !important", md:"16px !important"}}}>
                    <Typography
                      variant="h6"
                      sx={{ color: "#7E7E7E", textTransform: "uppercase" }}
                    >
                      Select Payment System
                    </Typography>
                  </Item>
                  <Divider
                    sx={{ border: "1px solid rgba(255, 255, 255, 0.2)" }}
                  />
                  <Item>
                    <Grid item xs={12} sm={12} md={12}>
                      <Card
                        style={content}
                        onClick={handleCryptoPayment}
                        sx={{
                          background: "rgba(255, 255, 255, 0.03) !important",
                          display: "flex",
                        }}
                        className={cryptoPayment? "activePayment":""}
                        //onClick={}
                      >
                        <CardMedia
                          component="img"
                          sx={{
                            borderRadius: "10px",
                            p: theme.spacing(0.3),
                            width: {md:"150.06px", xs:"30px"},
                            height: {md:"129.68px", xs:"30px"},
                          }}
                          image={cryptoPayments}
                          alt="Live from space album cover"
                        />
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <CardContent sx={{ flex: "1 0 auto", p: "0", mt: 2 }}>
                            <Stack spacing={2} flex="1 0 1">
                              <Box >
                                <Typography component="h6" variant="h6">
                                  Crypto Payment
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  component="h3"
                                  variant="h3"
                                  sx={{ color: "#A3A9AD" }}
                                >
                                  Minimum - 5(USD) | Maximum - 10.000(USD)
                                </Typography>
                              </Box>
  
                              <Box>
                                <Button
                                  sx={{
                                    px: "19px",
                                    py: "7.73px",
                                    marginBottom: "19px !important",
                                    backgroundColor: "rgba(60, 55, 255, 0.15)",
                                    color: "#3C37FF",
                                    borderRadius: "19px",
                                  }}
                                >
                                  No Payment fees
                                </Button>
                              </Box>
                            </Stack>
                          </CardContent>
                        </Box>
                      </Card>
                    </Grid>
                  </Item>
                  <Item>
                    <Grid item xs={12} sm={12} md={12}>
                      <Card
                        style={content}
                        onClick={handleCryptoPayment}
                        sx={{
                          background: "rgba(255, 255, 255, 0.03) !important",
                          display: "flex",
                        }}
                        className={!cryptoPayment? "activePayment":""}
                      >
                        <CardMedia
                          component="img"
                          sx={{
                            borderRadius: "10px",
                            p: theme.spacing(0.3),
                            width: {md:"150.06px", xs:"30px"},
                            height: {md:"129.68px", xs:"30px"},
                          }}
                          image={allPayments}
                          alt="Live from space album cover"
                        />
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <CardContent sx={{ flex: "1 0 auto", p: "0", mt: 2 }}>
                            <Stack spacing={2} flex="1 0 1">
                              <Box>
                                <Typography component="h6" variant="h6">
                                Fiat Payments
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  component="h3"
                                  variant="h3"
                                  sx={{ color: "#A3A9AD" }}
                                >
                                  Minimum - 5(USD) | Maximum - 10.000(USD)
                                </Typography>
                              </Box>
  
                              <Box>
                                <Button
                                  sx={{
                                    px: "19px",
                                    py: "7.73px",
                                    marginBottom: "19px !important",
                                    backgroundColor: "rgba(60, 55, 255, 0.15)",
                                    color: "#3C37FF",
                                    borderRadius: "19px",
                                  }}
                                >
                                  No Payment fees
                                </Button>
                              </Box>
                            </Stack>
                          </CardContent>
                        </Box>
                      </Card>
                    </Grid>
                  </Item>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          {cryptoPayment? <BankAccounts /> : <CryptoPaymentsModule /> }
        </Grid>
      </Box>
    );
  };
  