import * as React from "react";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { AppBar, Box, Grid, Menu, MenuItem } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import notificationlogo from "@/assets/inactiveNotification.svg";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { AccountCircle } from "@material-ui/icons";
import { useAuth } from "@/lib/auth";
import dropdownIcon from "@/assets/dropdownIcon.svg";
import world from "@/assets/world.svg";
import yellowt from "@/assets/yellow-t.png";
import wallet from "@/assets/wallet.svg";
import MenuIcon from "@mui/icons-material/Menu";
import logoCrypto from "@/assets/logoCrypto.svg";
import slogan from "@/assets/sloganCrypto.png";
import "../../../src/components/Elements/CSS/header.css";
import "../../../src/components/Elements/CSS/style.css";
import NotificationPopup from "./notification";
import Swal from "sweetalert2";
import { useWalletAmount } from "./api/getWalletamount";
import MervIcon from "@/assets/mervIcon.svg";
import CurrencyFormat from 'react-currency-format';
// import Notifications from "./notification";
declare global {
  interface Window { // ⚠️ notice that "Window" is capitalized here
    google: any;
    googleTranslateElementInit:any;
  }
}
export default function Header() {
  const { logout } = useAuth();
  const dataWallet = useWalletAmount({});
  const googleTranslateElementInit = () => {
    if (window.google && window.google.translate) {
      window.google.translate.TranslateElement({
        pageLanguage: "en",
        layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
        autoDisplay: true,
      }, "google_translate_element");
    } else {
      console.error("Google Translate API not loaded.");
    }
  };
  React.useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  const [sections, setSections] = React.useState([
    {
      title: "Lotteries",
      url: "/app/upComingLotteries",
      id: "0",
      active: false,
    },
    { title: "Live Draws", url: "/app/liveLotteries", id: "1", active: false },
    { title: "Last Draws", url: "/app/replayLottery", id: "2", active: false },
    { title: "Wallet", url: "/app/wallet/MyLottery", id: "3", active: false },
    { title: "Promotions", url: "/app/PromotionFreeTicket", id: "4", active: false },
    { title: "ICO", url: "/ICO", id: "5", active: false },
  ]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElLang, setAnchorElLang] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElAvatar, setAnchorElAvatar] =
    React.useState<null | HTMLElement>(null);
  const openLang = Boolean(anchorElLang);
  const openAvatar = Boolean(anchorElAvatar);

  const handleClickAvatar = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElAvatar(event.currentTarget);
  };
  const handleCloseAvatar = () => {
    setAnchorElAvatar(null);
  };

  const handleClickLang = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElLang(event.currentTarget);
  };
  const handleCloseLang = () => {
    setAnchorElLang(null);
  };
  const pages = ["Live Lottery", "Lotteries", "Promotions"];
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorElAvatar(null);
    Swal.fire({
      title: "Logout?",
      text: "Are you sure you want to logout?",
      icon: "question",
      showCancelButton: true,
      background: "black",
      color: "#ffff",
      customClass: {
        actions: "swal-actions-class",
        popup: "swal-popup-class",
      },
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      focusConfirm: true,
      allowOutsideClick: false,
      confirmButtonText: "Yes, logout",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
  
        setTimeout(()=>{
          logout();
        }, 2000)
        Swal.fire({
          icon: "success",
          background: "black",
          color: "#ffff",
          text: "Logged Out! You have been logged out",
        });

      }
    });
  };
  React.useEffect(() => {
    const updatedSections = sections.map((section) => {
      if (section.url === window.location.pathname) {
        return { ...section, active: true };
      } else {
        return { ...section, active: false };
      }
    });
    setSections(updatedSections);
  }, []);

  const handleLinkClick = (sectionId) => {
    const updatedSections = sections.map((section) => {
      if (section.id === sectionId) {
        return { ...section, active: true };
      } else {
        return { ...section, active: false };
      }
    });
    setSections(updatedSections);
  };
  return (
    <React.Fragment>
      <AppBar
        position="fixed"
        color="default"
        elevation={0}
        sx={{
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          zIndex: "10",
          background: "rgba(19, 20, 32, 0.4)",
        }}
      >
        <Toolbar sx={{ flexWrap: "wrap", flexFlow:"nowrap", zIndex: "1",  alignItems:"center", gap:"10px", justifyContent:"space-between"}}>
          <Box sx={{ display: { xs: "flex", md: "flex" }, alignItems:"center", gap:"10px", justifyContent:"space-between"}}>
            <Box
              className="mervlogo"
              sx={{ pr: { xs: "0px", sm: "0px", md: "12px", lg: "10px" }, paddingTop: "14px" }}
            >
              <Link variant="h3" href="/app">
                <img src={logoCrypto} className="logoImage" />
              </Link>
            </Box>
            <nav
              className="nav-landscape"
              style={{ display: "flex", alignItems: "center" }}
            >
              {sections.map((section, i) => (
                section.id != "5" ?
                  <Link
                    key={section.title}
                    variant="h3"
                    color="text.primary"
                    href={section.url}
                    textAlign="center"
                    underline="none"
                    sx={{
                      my: 1,
                      mx: 0.8,
                      // fontSize:'16px',
                      color: section.active ? "blue" : "#fff",
                      fontWeight: 900,
                    }}
                    onClick={() => handleLinkClick(section.id)}
                  >
                    {section.title}
                  </Link> :
                  <></>
              ))}
            </nav>
          </Box>
          <Box
            className="img-landscape-mid-logo"
            sx={{ display: { lg: "block", xs: "none" } }}
          >
            <img
            className="headerPunchLogo"
              src={slogan}
              style={{
                 width:"auto",
                 maxWidth:"fit-content", 
              }}
              alt="slogan"
            />
          </Box>
          {/* <Box sx={{ flexGrow: 1 }} /> */}
          <Box
            className="try"
            sx={{
              display: { md: "flex", xs: "flex" },
              width: { md: "auto" },
              justifyContent: "space-between",
            }}
          >
            <nav
              className="nav-landscape"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Link
                key={sections[5].title}
                variant="h3"
                color="text.primary"
                href={sections[5].url}
                textAlign="center"
                underline="none"
                sx={{
                  my: 1,
                  mx: 0.8,
                  // fontSize:'16px !important',
                  color: sections[5].active ? "blue" : "#fff",
                  fontWeight: 900,
                }}
                onClick={() => handleLinkClick(sections[5].id)}
              >
                {sections[5].title}
              </Link>
            </nav>

            <IconButton
              sx={{ p: { xs: "0px", sm: "0px", md: "12px" } }}
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <NotificationPopup />
            </IconButton>
            <Link
              href="/app/wallet/MyLottery"
              color={"#ffff"}
              underline="none"
              variant="h3"
              sx={{ pl: "7px", display: "flex", alignItems: "center" }}
            >
              <IconButton
                className="landscape-wallet-div"
                color="inherit"
                sx={{
                  backgroundColor: "#3C37FF",
                  p: { xs: "9px 12px", md: "9px 30px" },

                  borderRadius: "18px",
                  "&:hover": {
                    backgroundColor: "#3C37FF",
                  },
                  height: "41px",

                }}
              >
                <img src={wallet} />

                <span>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontWeight: "bold",
                      fontStyle: "normal",
                      color: "rgba(255, 255, 255, 0.8)",
                      fontSize: "16px",
                    }}
                  >
                    <img
                      src={yellowt}
                      style={{
                        height: "auto",
                        width: "17px",
                        marginRight: "3px",
                      }}
                    />
                    {/* {dataWallet?.data?.data?.walletAmount} */}
                    <CurrencyFormat value={dataWallet?.data?.data?.walletAmount} thousandSeparator={','} displayType={'text'} decimalSeparator={'.'} />
                  </Typography>
                </span>
              </IconButton>
            </Link>
            {/* <IconButton
            sx={{p:{xs:"6px",sm:"6px",md:"12px"}}}
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <img  src={world} alt="world"></img>
        
        </IconButton> */}
            {/* <Button
              id="basic-button-lang"
              aria-controls={openLang ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openLang ? "true" : undefined}
              onClick={handleClickLang}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "400px",
                  mr: "9px",
                  maxWidth: "32.5px",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                English
              </Typography>{" "}
              <img src={dropdownIcon} alt="dropdown icon"></img>
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorElLang}
              open={openLang}
              onClose={handleCloseLang}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleCloseLang}>
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontWeight: "400",
                    fontStyle: "normal",
                    color: "rgba(255, 255, 255, 0.8)",
                    fontSize: "16px",
                  }}
                >
                  English{" "}
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleCloseLang}>
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontWeight: "400",
                    fontStyle: "normal",
                    color: "rgba(255, 255, 255, 0.8)",
                    fontSize: "16px",
                  }}
                >
                  Spanish
                </Typography>{" "}
              </MenuItem>
              <MenuItem onClick={handleCloseLang}>
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontWeight: "400",
                    fontStyle: "normal",
                    color: "rgba(255, 255, 255, 0.8)",
                    fontSize: "16px",
                  }}
                >
                  Chinese
                </Typography>{" "}
              </MenuItem>
            </Menu> */}
            <div
            style={{
              margin: "18px 8px 8px 8px",
              padding: "8px 8px"
            }}>
            <div id="google_translate_element"></div>
            </div>
            <IconButton
              sx={{ padding: "0px" }}
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-haspopup="true"
              color="inherit"
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "16px",
                }}
              >
                <img
                  alt="avatar"
                  height={30}
                  width={30}
                  src={
                    dataWallet?.data?.data?.userImage
                      ? `${process.env.API_URL_PREFIX}/api/v1/user/uploads/${dataWallet?.data?.data?.userImage}`
                      : `${MervIcon}`
                  }
                  style={{ borderRadius: "50%" }}
                />
                <Button
                  sx={{
                    minWidth: "30px",
                    padding: "0px ",
                    position: "absolute",
                    width: "100% ",
                    height: "100%",
                    opacity: "0",
                  }}
                  id="basic-button-lang-1"
                  aria-controls={openAvatar ? "basic-menu-1" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openAvatar ? "true" : undefined}
                  onClick={handleClickAvatar}
                >
                  <img src={dropdownIcon} alt="login icon"></img>
                </Button>

                <Menu
                  id="basic-menu-1"
                  anchorEl={anchorElAvatar}
                  open={openAvatar}
                  onClose={handleCloseAvatar}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    onClick={() => window.location.assign("/app/myAccount")}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontWeight: "400",
                        fontStyle: "normal",
                        color: "rgba(255, 255, 255, 0.8)",
                        fontSize: "16px",
                      }}
                    >
                      My Account{" "}
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() => window.location.assign("/app/friend")}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontWeight: "400",
                        fontStyle: "normal",
                        color: "rgba(255, 255, 255, 0.8)",
                        fontSize: "16px",
                      }}
                    >
                      Friends
                    </Typography>{" "}
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontWeight: "400",
                        fontStyle: "normal",
                        color: "rgba(255, 255, 255, 0.8)",
                        fontSize: "16px",
                      }}
                    >
                      Logout
                    </Typography>{" "}
                  </MenuItem>
                </Menu>
                <img src={dropdownIcon} alt="login icon"></img>
              </Box>
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
