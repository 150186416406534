import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Divider,
  Grid,
  Link,
  Paper,
  Slider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { TopHeadingLayout } from "@/components/layout/TopHeadingLayout";
import HomeScreenImg from "@/assets/earth.jpg";
import comingsoon from "@/assets/comingSoon.png";
import upcomingImg from "@/assets/logo.png";
import { theme } from "@/components/Elements/theme";
import { useAuth } from "@/lib/auth";
import { useLotteries } from "../api/getLottery";
import { CircularProgress } from "@mui/material";
import { lotteryData } from "@/features/buyNow/types";
import Countdown from "react-countdown";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import yellowt from "@/assets/yellow-t.png";
import whitet from "@/assets/white-t.png";
import "../../../../src/components/Elements/CSS/style.css";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  paperClass: {
    padding: theme.spacing(2),
    textAlign: "center",
    background: "rgba(5, 5, 24, 0.8)",
    borderRadius: "10px",
  },
}));

const dataValue = {
  heading: "Buy Lottery Tickets Online",
  subHeading:
    "Buy lottery tickets online for the top international lotteries. Safely and securely playfor the biggest jackpots in the entire world from the comfort of home.",
};

export default function UpcomingLotteries() {
  const classes = useStyles();
  const { user } = useAuth();
  const [loading, setLoading] = React.useState(false);
  const LotteryQuery = useLotteries({});
  console.log("LotteryQuery", LotteryQuery);
  let navigate = useNavigate();

  const [countDown, setCountDown] = React.useState(new Date().getTime());

  // if (LotteryQuery.isLoading) {
  //     return (
  //       <>
  //   <CircularProgress />
  //   </>
  //     );
  //   }

  if (LotteryQuery.isLoading || LotteryQuery.isFetching) {
    // Render a loading indicator while the API is still fetching data
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress size={80} />
      </div>
    );
  }

  if (!LotteryQuery.data || LotteryQuery.data.length === 0) {
    // Render the "Coming Soon" screen when there is no data available
    return (
      <div
        className="w-full h-48 flex justify-center items-center"
        style={{
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          border: "1px solid",
          marginTop: "5%",
        }}
      >
        <img src={comingsoon} alt="comingsoon" style={{ width: "100%" }} />
      </div>
    );
  }

  const countDownDate = new Date(LotteryQuery?.data[0]?.endDate || 0).getTime();

  var content = {
    color: "#ffff",
    backgroundColor: "#3C37FF",
    borderRadius: "10px",
  };
  var center = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  var button = {
    background: "rgba(255, 255, 255, 0.13)",
    borderRadius: "15.507px",
    color: "#FFFFFF",
    "&:hover": {
      background: "rgba(255, 255, 255, 0.13)",
      borderRadius: "15.507px",
      color: "#FFFFFF",
    },
  };

  const handleSoldOut = (soldStatus, cardId) => {
    if (!soldStatus) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        navigate(`/app/Lotteries/${cardId}`);
      }, 500);
    } else {
      Swal.fire({
        icon: "error",
        background: "black",
        color: "#ffff",
        text: "Tickets are sold-out, please buy tickets in another lottery",
      });
    }
  };
  return (
    <Box
      sx={{
        background: `linear-gradient(89.41deg, #1B1C2D 7.81%, rgba(27, 28, 45, 0) 38.09%), linear-gradient(1.53deg, #1B1C2D 13.32%, rgba(27, 28, 45, 0) 50.41%), linear-gradient(46.49deg, #1B1C2D 14.7%, rgba(27, 28, 45, 0) 43.39%), url(${HomeScreenImg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minHeight: "100%",

        backgroundAttachment: "fixed",
        backgroundPosition: "center",
        paddingTop: "70px",
      }}
    >
      <Box
        sx={{ backgroundColor: "rgba(5, 5, 24, 0.8)", borderRadius: "10px" }}
      />
      <Box sx={{ p: { xs: "10px", md: "50px" } }}>
        <Grid
          container
          display={"flex"}
          direction={"column"}
          // marginTop={"60px"}
          sx={{ backgroundColor: "rgba(5, 5, 24, 0.8)", borderRadius: "10px" }}
        >
          <Grid item xs={12} sm={10} md={8} lg={6}>
            <Typography
              component="h4"
              variant="h4"
              sx={{
                marginLeft: { md: "50px", xs: "10px" },
                marginTop: { md: "40px", xs: "10px" },
              }}
            >
              Upcoming Lottery
            </Typography>
            <Grid
              className="small-device-upComingLotteries"
              container
              display={"flex"}
              direction={"row"}
              spacing={2}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                marginBottom: "40px",
                p: { md: "theme.spacing(1)", xs: "10px" },
                pt: { md: "20px", xs: "5px" },
              }}
            >
              <Backdrop
                open={loading}
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
              >
                <CircularProgress color="inherit" />
              </Backdrop>

              {LotteryQuery?.data.map((value, index) => (
                <Grid
                  item
                  key={index}
                  xs={3}
                  sm={3}
                  md={3}
                  lg={3}
                  xl={3}
                  className="inner-grid"
                >
                  <Card
                    style={content}
                    onClick={() => handleSoldOut(value?.soldStatus, value?._id)}
                    sx={{
                      maxWidth: "179px",
                      margin: "0 auto",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "#fff !important",
                        color: "black !important",
                        boxShadow: 20,
                        minHeight: "182px",
                        "& div div .MuiDivider-root": {
                          borderBottomColor: "black !important",
                        },
                        "& div h5": {
                          backgroundColor: "#fff !important",
                          color: "black !important",
                        },
                        "& div div .MuiCardContent-root": {
                          backgroundColor: "#fff !important",
                          color: "black !important",
                        },
                        "& div div div div.MuiPaper-root": {
                          border: "1.23px solid #00000038 !important",
                          background: "#fff",
                          boxShadow: "none",
                        },
                        "& div div button": {
                          background: "rgba(255, 255, 255, 0.13)",
                          borderRadius: "15.507px",
                          color: "#FFFFFF",
                        },
                        "&  .MuiCardActions-root  > button": {
                          background: "rgba(255, 255, 255, 0.13)",
                          borderRadius: "15.507px",
                          color: "#FFFFFF",
                        },
                      },
                    }}
                  >
                    <div style={{ position: "relative" }}>
                      <CardMedia
                        className="img-card"
                        sx={{
                          height: 156,
                          backgroundPosition: "initial",
                        }}
                        image={
                          value.image
                            ? `${process.env.API_URL_PREFIX}/api/v1/admin/uploads/${value.image}`
                            : upcomingImg
                        }
                        title="green iguana"
                      />

                      {value?.soldStatus ? (
                        <>
                          <Chip
                            label="Sold Out"
                            sx={{
                              position: "absolute",
                              transform: "rotate(-45deg)",
                              top: "7px",
                              left: "-39px",
                              backgroundColor: "red",
                              color: "#FFFFFF",
                              borderRadius: "4px",
                              padding: "8px 26px",
                              fontSize: "12px",
                              fontWeight: "bold",
                              zIndex: 1,
                            }}
                          />
                          {/* <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: 0,
            height: 0,
            borderBottom: '20px solid transparent', // Customize the size of the slanting line
            borderLeft: '20px solid #FF0000', // Match the background color of the label
            zIndex: 0,
          }}
        /> */}
                        </>
                      ) : (
                        ""
                      )}
                      {value?.numTktUserPurchase > 0 ? (
                        <>
                          {" "}
                          <Chip
                            label={value?.numTktUserPurchase + " Tickets"}
                            sx={{
                              position: "absolute",
                              transform: "rotate(45deg)",
                              top: "7px",
                              right: "-42px",
                              backgroundColor: "blue",
                              color: "#FFFFFF",
                              borderRadius: "4px",
                              padding: "8px 26px",
                              fontSize: "12px",
                              fontWeight: "bold",
                              zIndex: 1,
                            }}
                          />
                          {/* <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            width: 0,
            height: 0,
            borderTop: '20px solid transparent', // Customize the size of the slanting line
            borderLeft: '20px solid #FF0000', // Match the background color of the label
            zIndex: 0,
          }}
        /> */}
                        </>
                      ) : (
                        ""
                      )}
                    </div>

                    <CardContent
                      style={center}
                      onClick={() =>
                        handleSoldOut(value?.soldStatus, value?._id)
                      }
                      sx={{
                        flexDirection: "column",
                        pb: "0px",
                        cursor: "pointer",
                        height: "85px",
                        mt: "20px",
                      }}
                    >
                      {/* <Typography component="h5" variant="h5" gutterBottom>
              {value.lotteryName} 
             
              </Typography> */}
                      <Typography
                        component="h5"
                        variant="h5"
                        gutterBottom
                        sx={{ display: "flex" }}
                      >
                        <img
                          style={{
                            height: "auto",
                            width: "15px",
                            marginTop: "auto",
                            marginBottom: "auto",
                          }}
                          src={yellowt}
                        />{" "}
                        {value.ticketPrice}
                      </Typography>
                      <Typography
                        component="h5"
                        variant="h5"
                        gutterBottom
                        sx={{ fontSize: "16px", display: "flex" }}
                      >
                        <Countdown
                          date={new Date(`${value?.endDate}T${value?.endTime}`)}
                        />
                      </Typography>
                      <Box
                        sx={{
                          px: "14px",
                          py: "5.73px",
                          marginBottom: "5px !important",
                          background: "rgba(255, 255, 255, 0.5)",
                          borderRadius: "15.507px",
                          color: "#3C37FF !important",
                          width: "115px !important",
                          textAlign: "center",
                          fontWeight: "600",
                        }}
                      >
                        Buy Ticket
                      </Box>
                      <Typography
                        component="h5"
                        variant="h5"
                        gutterBottom
                        sx={{
                          fontSize: "16px",
                          display: "flex",
                          textAlign: "center",
                          mb: "5px",
                        }}
                      >
                        {
                          new Date(value?.lotteryDrawDate)
                            .toISOString()
                            .split("T")[0]
                        }{" "}
                        {new Date(value?.lotteryDrawDate)
                          .toISOString()
                          .split("T")[1]
                          .substring(0, 5) + "UTC"}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
