import * as React from "react";
import * as z from "zod";
import { useAuth } from "@/lib/auth";
import {
  TextField,
  Box,
  Typography,
  Button,
  Grid,
  FormControl,
  InputLabel,
  InputAdornment,
  Link,
  CircularProgress,
} from "@mui/material";
import inActiveMail from "@/assets/InActiveMail.png";
import activeMail from "@/assets/activeMail.svg";
import backLogin from "@/assets/loginBack.svg";
import logoCrypto from '@/assets/logoCrypto.svg';
import { useState } from "react";

import {forgetPasswordCredentialsDTO, forgetPasswordWithEmail} from "../api/forgetPassword"
import { toast } from "react-toastify";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";




type ForgetPasswordValues = {
  email: string;
};

const initialValues: ForgetPasswordValues = {
  email: ''
};

type ForgetPasswordProps = {
  onSuccess: () => void;
};

const formSchema = z
  .object({
    email: z.string().email("Invalid email").min(1, "Email is required"),
  })

  type FormSchemaType = z.infer<typeof formSchema>;

export const ForgetPasswordForm = ({ onSuccess }: ForgetPasswordProps) => {
  //const { forgotPassword } = useAuth();
  const [loading, setLoading] = useState(false);
  const [Icon, setIcon] = useState("");
  const [isOpen, setisOpen] = useState(false);
  const [formValues, setFormValues] = useState<ForgetPasswordValues>(initialValues);
  const [errors, setErrors] = useState<z.ZodIssue[]>([]);
  const {
    register,
    handleSubmit,
    formState: {  isSubmitting },
  } = useForm<FormSchemaType>({
    resolver: zodResolver(formSchema),
  });


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleClose = () => {
    setisOpen(false);
  };

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const result = formSchema.safeParse(formValues);
    let {email } = formValues;
    if (result.success) {
      const { email: email } = result.data;
      handleContinue(result.data);
    } else {
      setErrors(result.error.issues);
    }
  };

  const handleContinue = async (data) => {
    try{
      setLoading(true); 
    await forgetPasswordWithEmail(data);
    onSuccess();
    } catch (err: any){
      const message = err.response?.data?.message || err.message;
    }
    finally {
      setLoading(false); // Stop loading in both success and error cases
    }
    //setisOpen(true);
  };
  return (
    <>
      <Link variant="h3" href="/"
      sx={{display: {sm:"none", md:"block"}}}
              >
                <img src={logoCrypto} className="logoImage" /></Link>
      <Typography component="h1" variant="h1" sx={{ mt: {md:1, sm:0.2} }}>
        Forgot your password?
      </Typography>
      <Typography component="h6" variant="h6">
        Start the process to reset your password
      </Typography>
      <form onSubmit={handleFormSubmit}>
      <Box sx={{ mt: {md:"3.25", sm:"1" }}}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} sx={{ pt: 2 }}>
            <Box
              sx={{
                height: "77px",
                background: "#060622",
                border: "0.5px solid rgba(60, 55, 255, 0.24)",
                borderRadius: "10px",
                mr: "15px",
                mb: {md:"25px", sm:"10px"},
                opacity: "0.6",
              }}
            >
              <Typography
                component="h6"
                variant="h6"
                sx={{ p: {md:"14px 24px 0px", sm:"14px 24px 0px"} }}
              >
                Enter your registered email address below and we will send you
                link to reset password.
              </Typography>
            </Box>
          </Grid>
         
          <Grid item xs={12} sm={12} md={12} sx={{ pt: 2 }}>
            <FormControl fullWidth={true}>
              <InputLabel
                htmlFor="email"
                shrink={true}
                sx={{ color: "#FFFFFF" }}
              >
                Email Address
              </InputLabel>
              <TextField
                margin="normal"
                required
                id="email"
                name="email"
                onChange={handleChange}
                value={formValues.email}
                autoComplete="email"
                onFocus={(e) => {
                  setIcon(activeMail);
                }}
                onBlur={(e) => {
                  setIcon(inActiveMail);
                }}
                autoFocus
                placeholder="Enter your mail ID"
                InputProps={{
                  endAdornment: (
                    <InputAdornment disableTypography position="end">
                      <img src={Icon} alt="activeMail" />
                    </InputAdornment>
                  ),
                }}
              />
              {errors.find((err) => err.path[0] === 'email') && (
        <div style={{ color: '#FF0000', fontSize:"14px" }}>{errors.find((err) => err.path[0] === 'email')?.message}</div>
      )}
            </FormControl>
          </Grid>
        </Grid>
        {loading ? <CircularProgress size={24} color="inherit"/> :  <Button
          variant="contained"
          sx={{ mt:{xs:1, md:3}, mb: 2 }}
          type="submit"
        >
          Continue
        </Button>}
       
        <Grid container>
          <Grid item xs>
          <Typography variant="h3">
          <img
              src={backLogin}
              alt="backlogin"
              height={'14px'}
              width={'14px'}
            />
              <Link
                href="/auth/login"
                variant="h3"
                underline="none"
                sx={{ pl: "4px" }}
              >
                Back to Login
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Box></form>
    {/* <VerifyOTP open={isOpen} onClose={handleClose} /> */}
    </>
  );
};
