/* eslint-disable no-template-curly-in-string */
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import comingsoon from "@/assets/comingSoon.png";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Divider,
  Grid,
  Link,
  Paper,
  Slider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { TopHeadingLayout } from "@/components/layout/TopHeadingLayout";
import HomeScreenImg from "@/assets/earth.jpg";
import quickDrawsImg from "@/assets/quickDrawsImg.png";
import { useAuth } from "@/lib/auth";
import { useLotteries } from "../api/getLottery";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import Countdown from "react-countdown";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  paperClass: {
    padding: theme.spacing(2),
    textAlign: "center",
    background: "rgba(5, 5, 24, 0.8)",
    borderRadius: "10px",
  },
}));

const dataValue = {
  heading: "Live Lottery Spin",
  subHeading:
    "Buy lottery tickets online for the top international lotteries. Safely and securely playfor the biggest jackpots in the entire world from the comfort of home.",
};

export default function LiveLotteries() {
  const classes = useStyles();
  const { user } = useAuth();
  const LotteryQuery = useLotteries({});

  // if (LotteryQuery.isLoading) {
  //   return (
  //     <>
  //       <CircularProgress />
  //     </>
  //   );
  // }

  if (LotteryQuery.isLoading || LotteryQuery.isFetching) {
   
    return (
      <div style = {{display:"flex" , justifyContent:"center", alignItems:"center", height:"100vh"}}>
      <CircularProgress size={80}/>
      </div>
    );
  }
  
 
  
  if (!LotteryQuery.data || LotteryQuery.data.length === 0) {
   
    return (
      <div
        className="w-full h-48 flex justify-center items-center"
        style={{
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          border: "1px solid",
          marginTop: "5%"
        }}
      >
        <img src={comingsoon} alt="comingsoon" style={{ width: "100%" }} />
      </div>
    );
  }

  var content = {
    color: "#ffff",
    backgroundColor: "#050518",
    borderRadius: "12px",
  };
  var center = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const gridItem = {};
  return (
    
    
    <Box
      sx={{
        background: `linear-gradient(89.41deg, #1B1C2D 7.81%, rgba(27, 28, 45, 0) 38.09%), linear-gradient(1.53deg, #1B1C2D 13.32%, rgba(27, 28, 45, 0) 50.41%), linear-gradient(46.49deg, #1B1C2D 14.7%, rgba(27, 28, 45, 0) 43.39%), url(${HomeScreenImg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minHeight: "100%",
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
        padding: 0,
        paddingTop: "70px"
      }}
    >
      <Box
        sx={{
          backgroundColor: "rgba(5, 5, 24, 0.8)",
          borderRadius: "10px",
          marginTop: "0px",
        }}
      />
      <Box sx={{ p: { xs: "14px", md: "50px" } }}>
        <Grid
          container
          display={"flex"}
          direction={"column"}
          marginTop={"60px"}
          sx={{
            backgroundColor: "rgba(5, 5, 24, 0.8)",
            borderRadius: "10px",
            mt: "0px",
          }}
        >
          <Grid item xs={12} sm={10} md={8} lg={6}>
            <Typography
              component="h4"
              variant="h4"
              sx={{
                marginLeft: { md: "50px", xs: "10px" },
                marginTop: { md: "40px", xs: "10px" },
              }}
            >
              Live Lottery
            </Typography>
            <Grid
              container
              display={"flex"}
              direction={"row"}
              sx={{ marginBottom: "40px", p: "25px" }}
              alignItems="stretch"
              justifyContent="space-around"
              spacing={2}
            >
              {LotteryQuery.data?.map((value, index) => (
                //console.log(value)
                <Grid
                  item
                  style={gridItem}
                  sx={{ pt: { xs: "0px !important", md: "16px" } }}
                  key={index}
                  xs={3}
                  sm={3}
                  md={4}
                  lg={4}
                  xl={4}
                >
                  <Card
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", sm: "column", md: "row" },
                      maxWidth: "780px",
                      cursor: "pointer",
                      position: "relative",
                      "&:hover": {
                        backgroundColor: "#fff !important",
                        color: "black !important",
                        boxShadow: 20,

                        "& div div .MuiDivider-root": {
                          borderBottomColor: "black !important", // Change the color of the Divider component
                        },
                        "& div div div p": {
                          backgroundColor: "#fff !important",
                          color: "black !important",
                        },
                        "& div div .MuiCardContent-root": {
                          backgroundColor: "#fff !important",
                          color: "black !important",
                        },
                        "& div div div div.MuiPaper-root": {
                          border: "1.23px solid #00000038 !important",
                          background: "#fff",
                          boxShadow: "none",
                        },
                        "& div div div div.MuiPaper-root .MuiCardContent-root":
                        {
                          border: "1.23px solid #00000038 !important",
                        },
                      },
                    }}
                    style={content}
                    onClick={() => {
                      window.location.assign("/app/liveLotteries/" + value._id);
                    }}
                  >
                    <CardMedia
                      component="img"
                      image={
                        value.image
                          ? `${process.env.API_URL_PREFIX}/api/v1/admin/uploads/${value.image}`
                          : quickDrawsImg
                      }
                      alt="Live from space album cover"
                      sx={{
                        height: { xs: "200px !important", md: "250px !important" },
                        width: { xs: "100%", md: "45%" },
                        zIndex: "2",
                        backgroundPosition: 'initial',
                        objectFit: 'unset !important',
                        borderRadius: "10px",
                      }}
                    />


                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        cursor: "pointer",
                      }}
                    >
                      <CardContent style={center} sx={{}}>
                        <Stack
                          flex="1 0 1"
                          justifyContent={center}
                          alignItems={center}
                          sx={{
                            "&:hover": {
                              backgroundColor: "#fff !important",
                              color: "black !important",
                            },
                          }}
                        >
                          <Box>
                            <Button
                              sx={{
                                px: "19px",
                                py: "7.73px",
                                marginBottom: "19px !important",
                                backgroundColor: "rgba(60, 55, 255, 0.15)",
                                color: "#3C37FF",
                                borderRadius: "19px",
                                display: { xs: "none", md: "block" },
                              }}
                            >
                              {value.lotteryName}
                            </Button>
                            <Divider
                              id="cardLine"
                              sx={{
                                display: { xs: "none", md: "block" },
                                borderBottom:
                                  "0.727162px dashed rgba(255, 255, 255, 0.5)",
                                mx: "-80px",
                                "&:hover": {
                                  borderBottom:
                                    "0.727162px dashed black !important",
                                },
                              }}
                            />
                          </Box>
                          <Box>
                            <Typography component="h6" variant="h6">
                              {value.firstDrawPrize}
                            </Typography>
                          </Box>
                          <Box sx={{ marginTop: "20px" }}>
                            {" "}
                            <Card
                              sx={{
                                maxWidth: "144px",
                                width: "144px",
                                border: "1.2px solid rgba(255, 255, 255, 0.2)",
                              }}
                              style={content}
                            >
                              <div style={{ position: "relative" }}>
                                <CardMedia
                                  sx={{
                                    height: "40.5px",
                                    backgroundColor: "#3C37FF",
                                    position: "relative",
                                    backgroundPosition: "initial",
                                  }}
                                  title="Next Draw"
                                  style={center}
                                >
                                  Next Draw
                                </CardMedia>
                              </div>
                              <CardContent
                                sx={{
                                  padding: "7px",
                                  paddingBottom: "7px !important",
                                }}
                                style={center}
                              >
                                <Typography
                                  sx={{ marginBottom: "0px" }}
                                  gutterBottom
                                >
                                  <Countdown
                                    date={new Date(`${value?.lotteryDrawDate}`)}
                                  />
                                </Typography>
                              </CardContent>
                            </Card>
                          </Box>
                        </Stack>
                      </CardContent>
                    </Box>


                    
                    {value?.soldStatus ? (
                      <Chip
                        label="Sold Out"
                        sx={{
                          position: "absolute",
                          transform: "rotate(-45deg)",
                          top: "2px",
                          left: "-31px",
                          backgroundColor: "red",
                          color: "#FFFFFF",
                          borderRadius: "4px",
                          padding: "4px 17px",
                          fontSize: "12px",
                          fontWeight: "bold",
                          zIndex: "1000",
                        }}
                      />) : <></>
                    }
                    {value?.numTktUserPurchase > 0 ? (
                      <>
                        {" "}
                        <Chip
                          label={value?.numTktUserPurchase + " Tickets"}
                          sx={{
                            position: "absolute",
                            transform: "rotate(45deg)",
                            top: "7px",
                            right: "-42px",
                            backgroundColor: "blue",
                            color: "#FFFFFF",
                            borderRadius: "4px",
                            padding: "8px 26px",
                            fontSize: "12px",
                            fontWeight: "bold",
                            zIndex: { md: 1, xs: "1000" },
                          }}
                        />
                        {/* <div
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            width: 0,
                            height: 0,
                            borderTop: "20px solid transparent", // Customize the size of the slanting line
                            borderLeft: "20px solid #FF0000", // Match the background color of the label
                            zIndex: 0,
                          }}
                        /> */}
                      </>
                    ) : (
                      ""
                    )}
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
