import { Box, Grid , Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import FaqQ from "react-faq-component";
import addQues from "@/assets/addQues.svg";
import reduceQues from "@/assets/reduceQues.svg";
import "./faq.css"
import { TopHeadingLayout } from "@/components/layout/TopHeadingLayout";
import { useAuth } from "@/lib/auth";
import Header from "@/components/Elements/header";
import HeaderValue from "@/components/Elements/headerMain";
import Footer from "@/components/Elements/footer";
import HomeScreenImg from "@/assets/earth.jpg";
import { getFaqList } from "../api/getFaqList";





const data = {
    rows: [
        {
            title: "About the G2F Crypto Fortune Lottery",
            content: `Lorem Ipsum is simply dummy text 
            of the printing and typesetting industry. </br>
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.`,
        },
        {
            title: "Registration & Account",
            content:
                "Nunc maximus, magna at ultricies elementum, risus turpis vulputate quam, vitae convallis ex tortor sed dolor.",
        },
        {
            title: "How to Play?",
            content: `Curabitur laoreet, mauris vel blandit fringilla, leo elit rhoncus nunc, ac sagittis leo elit vel lorem.
            Fusce tempor lacus ut libero posuere viverra. Nunc velit dolor, tincidunt at varius vel, laoreet vel quam.
            Sed dolor urna, lobortis in arcu auctor, tincidunt mattis ante. Vivamus venenatis ultricies nibh in volutpat.
            Cras eu metus quis leo vestibulum feugiat nec sagittis lacus.Mauris vulputate arcu sed massa euismod dignissim. `,
        },
        {
            title: "Winning",
            content: <p>current version is 1.2.1</p>,
        },
        {
            title: "Payments and Deposits",
            content: <p>current version is 1.2.1</p>,
        },
        
    ],
};

const styles = {
    //bgColor: 'white',
    bgColor:"rgba(5, 5, 24, 0.8)",
    titleTextColor: "blue",
    rowTitleColor: "rgba(255, 255, 255, 0.8)",
    rowContentColor: "rgba(255, 255, 255, 0.6)",
    // arrowColor: "red",
};

const config = {
    // animate: true,
    // arrowIcon: "V",
    // tabFocus: true
    animate: true,
    arrowIcon: "V",
    openOnload: 0,
    expandIcon: <img src={addQues} alt="waving" />,
    collapseIcon:  <img src={reduceQues} alt="waving" />,
};
const dataValue = {
    "heading":"Frequently Asked Questions",
    "subHeading":"choose a category below for immediate online lottery help! If our FAQ section has not answered your inquiry please contact us and our Customer Service team will be happy to further assist you!",
}


export const FAQData = () => {
    const auth = useAuth();
    
    
    const [dataValues, setDataValues] = React.useState({});


    useEffect(() => {
        async function transformFAQData() {
            const faqData = await getFaqList();
            const transformedData = faqData.map(item => ({
              title: item.question,
              content: item.answer
            }));
            setDataValues(transformedData)
            return transformedData;
          }
        
          transformFAQData();
    }, []);

   

   
    
    return (
        <>
         {auth.user? <Header />: <HeaderValue />}
         <Box sx={{
            background: `linear-gradient(89.41deg, #1B1C2D 7.81%, rgba(27, 28, 45, 0) 38.09%), linear-gradient(1.53deg, #1B1C2D 13.32%, rgba(27, 28, 45, 0) 50.41%), linear-gradient(46.49deg, #1B1C2D 14.7%, rgba(27, 28, 45, 0) 43.39%), url(${HomeScreenImg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            minHeight: "100%",
            backgroundAttachment: "fixed",
            backgroundPosition: "center",
            padding: {xs:"80px 20px 20px 20px",md:"110px 20px 20px 20px"},
            // mx:"20px"
        }}>

<Grid item xs={12} justifyContent={"space-around"} pb={2} pr={2} pl={2}>
              <Typography
                variant="h2"
                align="center"
                // sx={{ mt: { md: "50px" } }}
              >
                FAQ
              </Typography>
            </Grid>
        
        <Box sx={{ backgroundColor: "rgba(5, 5, 24, 0.8)", borderRadius: "10px", pt:{xs:"10px", md:"80px"} }} >
            <Box sx={{ px: "90px", borderRadius: "10px", py: "70px" }}>
                <FaqQ
                    data={{
                        rows: dataValues
                      }}
                    styles={styles}
                    config={config} />
            </Box>
        </Box>
        </Box>
        <Footer/>
        </>
    );
}