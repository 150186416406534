import React, { useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import throttle from "lodash/throttle";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import { allData } from "./constants";
import storage from "@/utils/storage";
import axios from "axios";
import "./transaction.css";
import { Typography } from "@mui/material";
import MervIcon from "@/assets/mervIcon.svg";
import { useWalletAmount } from "@/components/Elements/api/getWalletamount";
import { useInvestedCoins } from "../api/getInvestedCoins";
import yellowt from "@/assets/yellow-t.png";
import CurrencyFormat from 'react-currency-format';
const tableHead = {
  transactionId: "Transaction Id",
  LotteryName: "Lottery Name",
  userName: "User Name",
  ticketNumber:"Ticket Number",
  amount: "Amount",
  freeTicketAmt: "Free Ticket Amount",
  lotteryWinningPrize: "Lottery Win Prize",
  commissionAmount: "Commission Amount",
  payoutAmount: "Payout Amount",
  payoutRequest: "Payout Request",
  transactionStatus: "Transaction Status",
  createdDate: "Created Date",

};

const MyTransactionList = () => {
  const countPerPage = 10;
  const dataWallet = useWalletAmount({});
  const coins = useInvestedCoins({});
  const [value, setValue] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalRecords, setTotalRecords] = React.useState(10);
  const [data, setData] = useState([{
    LotteryName: "",
    amount: 0,
    commissionAmount: "",
    createdDate: "",
    lotteryWinningPrize: "0",
    payoutAmount: "",
    transactionId: "",
    transactionStatus: "",
    userName: "",
    ticketNumber:""
  }])
  const [collection, setCollection] = React.useState(
    cloneDeep(allData.slice(0, countPerPage))
  );
  const searchData = React.useRef(
    throttle((val) => {
      const query = val.toLowerCase();
      setCurrentPage(1);
      const data = cloneDeep(
        allData
          .filter((item) => item.name.toLowerCase().indexOf(query) > -1)
          .slice(0, countPerPage)
      );
      setCollection(data);
    }, 400)
  );

  React.useEffect(() => {
    if (!value) {
      updatePage(1);
    } else {
      searchData.current(value);
    }
  }, [value]);

  useEffect(() => {
    getData(value, 1);
  }, [value])

  const getData = async (value, p) => {
    let token = storage.getToken();
    let pageLimit;
    pageLimit = value != "" ? totalRecords : 10
    const payable = {
      page: p,
      limit: pageLimit
    }
    await axios
      .post(
        `${process.env.API_URL_PREFIX}/api/v1/user/myTransactionList?search=${value}`,
        payable,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        setData(res.data.data)
        setTotalRecords(res.data.transactionPageCount)
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updatePage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    setCollection(cloneDeep(allData.slice(from, to)));
    getData(value, p);
  };
  console.log("?search=", value)
  const tableRows = (rowData) => {
    const { key, index } = rowData;
    const tableCell = Object.keys(tableHead);
    const columnData = tableCell.map((keyD, i) => {
      return <td key={i} className="td">{key[keyD]}</td>;
    });
    return <tr key={index} style={{ textAlign: "center" }}>{columnData}</tr>;
  };

  const tableData = () => {
    return data.map((key, index) => tableRows({ key, index }));
  };

  const headRow = () => {
    return Object.values(tableHead).map((title, index) => (
      <td key={index} ><div className="m-auto minw-maxcontent" >{title}</div></td>
    ));
  };
  console.log("totalRecords", totalRecords)
  return (
    <>

      <div>
        <input
          placeholder="Search"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className="search"
        />
          <Typography
                    variant="h5"
                    sx={{
                      position: "absolute",
                      right: "10%",
                      margin: "-18px",
                      pt: { xs: "5px", md: "0" },
                    }}
                    style={{margin: "-58px 10px"}}
                  >
                    <img
                      src={MervIcon}
                      className="log-merv-wallet"
                      style={{
                        height: "22px",
                        width: "22px",
                        position: "relative",
                        top: "5px",
                      }}
                    />{" "}
                    {coins?.data?.data} <br />
                    <img
                      src={yellowt}
                      className="log-merv-wallet"
                      style={{
                        height: "22px",
                        width: "22px",
                        position: "relative",
                        top: "5px",
                        right: "5px",
                      }}
                    />
                    {/* {dataWallet?.data?.data?.walletAmount} */}
                    <CurrencyFormat value={dataWallet?.data?.data?.walletAmount} thousandSeparator={','} displayType={'text'} decimalSeparator={'.'} />
                  </Typography>
      </div>
      <table cellPadding="5" cellSpacing="10" className="table MyTransactionList">
        <thead >
          <tr style={{ textAlign: "center" }}>{headRow()}</tr>
        </thead>
        <tbody className="trhover">{tableData()}</tbody>
      </table>
      {
        value == "" ? <Pagination
          pageSize={countPerPage}
          onChange={updatePage}
          current={currentPage}
          total={totalRecords}
        /> : ""
      }
    </>
  );
};
export default MyTransactionList;
