import { Close } from "@mui/icons-material";
import {
  Box,
  Stack,
  IconButton,
  Typography,
  Grid,
  Button,
  CircularProgress,
  Snackbar, 
  Alert,
} from "@mui/material";
import { CustomModel } from "@/components/Elements/custom-modal";
import openMail from "@/assets/closedMail.svg";
import OtpInput from "react-otp-input";
import { useEffect, useState } from "react";
import { useAuth } from "@/lib/auth";
import closeModal from "@/assets/closeModal.svg";
import storage from "@/utils/storage";
import { useNavigate } from "react-router-dom";
import axios from "axios"; 

interface VerifyEmailChangeProps {
  open: boolean;
  onClose: () => void;
  email: string; 
}

const VerifyEmailChange = ({ open, onClose, email }: VerifyEmailChangeProps) => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const { login } = useAuth();
  const [isVerifying, setIsVerifying] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); 

  
  useEffect(() => {
    if (open) {
      setOtp(""); 
      setErrorMessage(""); 
    }
  }, [open]);

  const handleContinue = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsVerifying(true);
  
    const payload = { OTP: otp }; 
    const token = storage.getToken(); 
  
    try {
      const response = await axios.post(
        `${process.env.API_URL_PREFIX}/api/v1/user/verifyEmailChange`, 
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
  
     
      if (response.status === 200) {
        console.log("Email verification successful:", response.data);
        navigate("/app"); 
        onClose();
      }
    } catch (error: any) {
  
      if (error.response && error.response.status === 400) {
      
        setErrorMessage(error.response.data?.message || "Invalid OTP. Please try again.");
      } else {
        setErrorMessage("An error occurred. Please try again later."); 
      }
  
      console.error("Email verification failed:", error.response?.data || error.message);
    } finally {
      setIsVerifying(false); 
    }
  };
  
  

  return (
    <CustomModel
      open={open}
      onClose={onClose}
      childComponent={
        <>
          <Stack direction="row" height="100%" sx={{ p: 0 }}>
            <Box
              sx={{ width: "100%", overflow: "auto", my: { md: "50px", xs: "20px" }, ml: "50px" }}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <img
                src={openMail}
                alt="mailIcon"
                style={{ textAlign: "center", display: "block" }}
                width="60px"
                height="60px"
                className="image-otp"
              />

              <Typography
                component="h4"
                variant="h4"
                sx={{ mt: { md: "20px", xs: "7px" }, textAlign: "center" }}
              >
                Check your Email
              </Typography>
              <Typography
                component="h6"
                variant="h6"
                sx={{ textAlign: "center", mt: { xs: "5px", md: "15px" }, mb: "20px", ml: { xs: "15px", md: "0px" } }}
              >
                We sent a verification code to <br /> <span style={{ color: "#3C37FF" }}>{email}</span>
              </Typography>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderSeparator={<span style={{ marginRight: "10px" }}></span>}
                renderInput={(props) => <input {...props} />}
                inputStyle={"input-otp"}
              />
              <Grid container>
                <Grid
                  item
                  lg={12}
                  sx={{
                    mt: { xs: 1, md: 3 },
                    mb: { xs: "30px", md: "45px" },
                    ml: { xs: "137px", md: "0px" },
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={handleContinue}
                    sx={{ px: { md: "24px !important", xs: "10px !important" } }}
                    disabled={isVerifying}
                  >
                    {isVerifying ? (
                      <CircularProgress size={24} color="inherit" /> 
                    ) : (
                      "Verify"
                    )}
                  </Button>
                </Grid>
              </Grid>

            
              <Snackbar
                open={Boolean(errorMessage)} 
                autoHideDuration={6000} 
                onClose={() => setErrorMessage("")} 
              >
                <Alert onClose={() => setErrorMessage("")} severity="error" sx={{ width: '100%' }}>
                  {errorMessage}
                </Alert>
              </Snackbar>
            </Box>
            <Box>
              <IconButton sx={{ padding: { md: "25px", xs: "10px" } }} onClick={onClose}>
                <img src={closeModal} />
              </IconButton>
            </Box>
          </Stack>
        </>
      }
    />
  );
};

export default VerifyEmailChange;
